export const imgTop = require('../assets/topImg.png')
export const imgBottom = require('../assets/bottomImg.png')
export const imgPQ = require('../assets/pqImg.png')
export const imgBO = require('../assets/bqImg.png')
export const imgPSO = require('../assets/psoImg.png')
export const img02 = require('../assets/image_02.png')
export const img03 = require('../assets/image_03.png')
export const img04 = require('../assets/image_04.png')
export const imgPQ1 = require('../assets/pq1Img.png')
export const imgBO1 = require('../assets/boImg1.png')
export const imgPSO1 = require('../assets/psoImg1.png')
export const imgBg = require('../assets/view_bg.png')
export const imgPoint = require('../assets/point.png')
export const imgLanding = require('../assets/landingTop.png')
export const imgSearch = require('../assets/search.png')