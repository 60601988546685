import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import { Box, Button, Paper, Typography } from "@material-ui/core";

const imgLogo = require('./logo.png')

interface myProps {
}

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: 'none',
        borderRadius: '0px',
        background: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '116px',
        maxWidth: '90vw',
        flex: 1
    },
    leftBox: {
        display: 'flex',
        alignItems: 'center'
    },
    rightBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '36px'
    },
    signupBtn: {
        height: '52px',
        backgroundColor: '#FFFFFF',
        color: '#6A299F',
        whiteSpace: 'nowrap',
        borderRadius: '12px',
        padding: '10px 16px',
        fontSize: '23px',
        fontWeight: 700,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#FFFFFF'
        }
    },
    loginBtn: {
        height: '52px',
        backgroundColor: 'transparent',
        color: '#FFFFFF',
        borderRadius: '12px',
        padding: '10px 16px',
        fontSize: '23px',
        fontWeight: 700,
        textTransform: 'none'
    },
}));

export default function LandingPageHeader(props: myProps) {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <Box maxWidth="xl" className={classes.container}>
                <Box className={classes.leftBox} >
                    <img src={imgLogo} alt="logo" />
                </Box>
                <Box className={classes.rightBox} >
                    <Typography
                        style={{
                            color: '#FFFFFF',
                            fontSize: '23px',
                            cursor: 'pointer',
                            fontWeight: window.location.pathname === '/' ? 700 : 500,
                            textDecoration: window.location.pathname === '/' ? 'underline' : 'none'
                        }}
                        onClick={() => window.location.href = "/"} >
                        Home
                    </Typography>
                    <Typography 
                    style={{ 
                        color: '#FFFFFF', 
                        fontSize: '23px', 
                        cursor: 'pointer',
                        fontWeight: window.location.pathname === '/FAQsPage' ? 700 : 500, 
                        textDecoration: window.location.pathname === '/FAQsPage' ? 'underline' : 'none' 
                        }}
                        onClick={() => window.location.href = "/FAQsPage"}>
                            FAQ’s
                        </Typography>
                    <Button className={classes.signupBtn} onClick={() => window.location.href = "/CountryCodeSelector"} >Sign up</Button>
                    <Button className={classes.loginBtn} onClick={() => window.location.href = "/EmailAccountLogin"}>Login</Button>
                </Box>
            </Box>
        </Paper>
    );
}
