import { Typography } from "@material-ui/core";
import React from "react";

const TextLabel = ({ text }: { text: string }) => {
  return (
    <Typography
      style={{
        color: '#000',
        fontFamily: 'HK Grotesk',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.42px',
        marginBottom: '0.8rem',
      }}>
      {text}
    </Typography >
  )
}

export default TextLabel;
