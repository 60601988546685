import { Button, Typography } from "@material-ui/core";
import React from "react";

const logo = require('../../blocks/email-account-registration/assets/logo.png')
const imageSaly = require('../../blocks/email-account-registration/assets/image_saly.png')

interface CongratulationScreenProps {
  navigation: any;
}

const CongratulationScreen = ({ navigation }: CongratulationScreenProps) => {
  return (
    <div>
      <img src={logo} style={{
        height: '60px',
        width: '180px',
        position: 'absolute',
        left: '50px',
        top: '55px' 
      }} />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <img src={imageSaly} style={{
          height: '452px',
          width: '452px'
        }}/>
        <Typography
          data-testid="TitleText"
          style={{
            fontFamily: "HK Grotesk",
            fontSize: "48px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "140%",
            marginTop: '55px',
            color: 'var(--On-Background, #030F2D)'
          }}
        >
          Congratulations
        </Typography>
        <Typography
          data-testid="DescriptionText"
          style={{
            color: "var(--On-Background, #030F2D)",
            fontFamily: "HK Grotesk",
            fontSize: "19px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "140%",
            marginTop: "12px",
            marginBottom: "72px"
          }}
        >
          You have successfully set up your business account.
        </Typography>
        <Button
          data-testid="BUTypeNextButton"
          style={{
            display: "flex",
            width: "100%",
            padding: "1rem",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "12px",
            background: "#5D248A",
            color: "var(--basic-white, #FFF)",
            fontFamily: "HK Grotesk",
            fontWeight: 700,
            textTransform: "capitalize",
          }}
          onClick={() => {
            navigation.navigate('SuggestionPage')
          }}
        >
          Go to Homepage
        </Button>
      </div>
    </div>
  )
}

export default CongratulationScreen;
