export const imgHome = require('../assets/home.png')
export const imgMessage = require('../assets/message.png')
export const imgCommunity = require('../assets/community.png')
export const imgSetting = require('../assets/setting.png')
export const imgNetwork = require('../assets/network.png')

export const imgRedirect = require('../assets/redirect.png')
export const imgLogo = require('../assets/image_logo.png')

export const imgHome1 = require('../assets/home1.png')
export const imgMessage1 = require('../assets/message1.png')
export const imgCommunity1 = require('../assets/community1.png')
export const imgSetting1 = require('../assets/setting1.png')
export const imgNetwork1 = require('../assets/network1.png')
