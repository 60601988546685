import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Paper, 
  InputAdornment,
  Input,
  IconButton,
  // Customizable Area End

} from "@material-ui/core";
const background = require('../assets/background.png');
// Customizable Area Start




// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  // Customizable Area End
 
  render() {
    return (
      // Customizable Area Start
      <div>
         <Paper
          data-testid='Background'
          style={{
            width: '100vw',
            height: '100vh',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
          style={{
            height: '80vh',
            width: '47vw',
            background: '#FFFEFE',
            borderRadius: '12px',
            marginRight: '14vw',
            boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
            maxHeight: '818px',
            maxWidth: '684px'
            // position:'rela'
          }}
          >
            <div
            className="country-select"
            style={{
              position: 'relative',
              height: '80vh',
              boxSizing: 'border-box',
              padding: '5rem 4rem',
            }}
            >
              <div
              // style={{border:'1px solid red'}}
              >
                <Typography
                  data-testid='CreateAccountDescription'
                  style={{
                    color: 'black',
                    fontFamily: 'HK Grotesk',
                    fontStyle: 'normal',
                    fontSize: '36px',
                    fontWeight: 'bold',
                    lineHeight: '140%',
                    marginBottom: '15px',
                  }}
                >
                  Forgot Password ?
                </Typography>
                <Typography
                  data-testid='CreateAccountDescription'
                  style={{
                    color: 'var(--Subtext, #999BAC)',
                    fontFamily: 'HK Grotesk',
                    lineHeight: '140%',
                    fontSize: '19px',
                    fontStyle: 'normal',
                    fontWeight: 500,                  
                    marginBottom: '64px',
                  }}
                >
                  Enter your email to get a link to create a new password
                </Typography>
              </div>
              <form
                style={{
                  height: '45vh',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  // border:'1px solid green',
                  marginTop: '-30px'
                }}
              >               
                <Typography variant="h6"
                style={{
                  color: 'black',
                  fontFamily: 'HK Grotesk',
                  fontSize: '19px',
                  fontStyle: 'normal',
                  lineHeight: '140%',
                  marginBottom: '10px',
                }}
                >
                  Enter your email address
                </Typography>
                <Input
                  data-testid="txtInputPassword"
                  // type={this.state.enablePasswordField ? "password" : "text"}
                  placeholder={"New Password"}
                  value={this.state.email}
                  onChange={(e) => this.setEmail(e.target.value)}
                  fullWidth={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                       
                      </IconButton>
                    </InputAdornment>
                  }
                />
                
                <Button
                data-testid='NextButton'
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '12px',
                  background:'#5D248A',
                  color: 'var(--basic-white, #FFF)',
                  fontFamily: 'HK Grotesk',
                  fontWeight: 700,
                  textTransform: 'capitalize',
                  marginTop: '2rem',
                  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                }}
                  onClick={() => this.sendResetLink()}
                >
                  Create
                </Button>
                <Typography
                style={{
                  color: 'var(--Subtext, #999BAC)',
                  fontFamily: 'HK Grotesk',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '140%',
                  marginTop: '2rem',
                  textAlign: 'center',
                }}
                >
                  Already have an account? Signin here
                </Typography>
              </form>
            </div>
          </Box>
        </Paper>
      </div>
      
  // Customizable Area End
);
  }
}

// Customizable Area Start


// Customizable Area End

