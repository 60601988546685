import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { Box, Paper } from "@material-ui/core";
import BusinessInformation from "../../../components/src/BusinessInformation";
import ContactInformation from "../../../components/src/ContactInformation";
import BusinessType from "../../../components/src/BusinessType";
import Categories from "../../../components/src/Categories";
import Products from "../../../components/src/Products";
import ProfilePicture from "../../../components/src/ProfilePicture";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CongratulationScreen from "../../../components/src/CongratulationScreen";

const background = require("../assets/background.png");
const backgroundWhite = require("../assets/backgroundWhite.png");

// Customizable Area End
const configJSON = require("./config");

interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  businessType: string;
  businessName: string;
  establishedYear: string;
  streetAddress: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
  contactName: string;
  numberCode: string;
  contactNumber: string;
  designation: string;
  websiteLink: string;
  arrayCategories: Array<{ id: number, name: string }>;
  category: string;
  arraySubcategories: Array<{ id: number, name: string }>;
  subcategory: string;
  arrayProducts: Array<{ id: number, type: string, name: string }>;
  products: Array<{ type: string, text: string }>;
  avatar: any;
  page: number;
  // Customizable Area End
}

interface SS { }

export default class BusinessAndContacts extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCategoriesApiCallId: any;
  getSubcategoriesApiCallId: any;
  getProductsApiCallId: any;
  putProfilePictureApiCallId: any;

  handleBusinessTypeChange = (businessType: string) => {
    this.setState({ businessType })
  }

  handleBusinessInformationChange = (data: {
    businessName: string;
    establishedYear: string;
    streetAddress: string;
    city: string;
    state: string;
    country: string;
    pinCode: string;
  }) => {
    this.setState(data)
  }

  handleContactInformationChange = (data: {
    contactName: string;
    numberCode: string;
    contactNumber: string;
    designation: string;
    websiteLink: string;
  }) => {
    this.setState(data)
  }

  handleProductsChange = (data: {
    products: Array<{ type: string, text: string }>,
  }) => {
    this.setState(data);
  }

  handleCategoryChange = (data: {
    category: string,
    subcategory: string,
  }) => {
    this.setState(data);
  }

  handleProfilePictureChange = (avatar: any) => {
    this.setState({ avatar: avatar.avatar })
    this.uploadProfilePic();
  };

  changePage = (page: number) => {
    this.setState({ page })
  }

  getCategories(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_categories/categories'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGetCategories
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSubcategories(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubcategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_categories/sub_categories'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGetCategories
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getProducts(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_categories/products'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGetCategories
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  uploadProfilePic(): boolean {
    const header = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putProfilePictureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/upload_profile_picture'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData()
    formData.append('id', localStorage.getItem('accountId') || '')
    formData.append('profile_picture', this.state.avatar as Blob)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePutAvatar
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.actionOnSuccessResponse(apiRequestCallId, responseJson, errorReponse)
      }
    }
    // Customizable Area End
  }

  actionOnSuccessResponse(apiRequestCallId: any, responseJson: any, errorReponse: any) {
    if (apiRequestCallId === this.getCategoriesApiCallId) {
      this.setState({ arrayCategories: responseJson.data.map((e: any) => ({ id: e.attributes.id, name: e.attributes.name }))})
    }
    if (apiRequestCallId === this.getSubcategoriesApiCallId) {
      this.setState({ arraySubcategories: responseJson.data.map((e: any) => ({ id: e.attributes.id, name: e.attributes.name }))})
    }
    if (apiRequestCallId === this.getProductsApiCallId) {
      this.setState({ arrayProducts: responseJson.data.map((e: any) => ({ id: e.attributes.id, type: e.attributes.sub_category.name, name: e.attributes.name }))})
    }
  }

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    // Customizable Area Start

    this.state = {
      businessType: '',
      businessName: '',
      establishedYear: '',
      streetAddress: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
      contactName: '',
      numberCode: '',
      contactNumber: '',
      designation: '',
      websiteLink: '',
      arrayCategories: [],
      category: '',
      arraySubcategories: [],
      subcategory: '',
      arrayProducts: [],
      products: [],
      avatar: null,
      page: 1,
    };

    this.getCategories = this.getCategories.bind(this);
    this.getSubcategories = this.getSubcategories.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <Box data-testid="Container" style={{ zIndex: 99 }}>
        <Paper
          data-testid="Background"
          style={{
            width: "100vw",
            height: "calc(100vh - 40px)",
            backgroundImage: this.state.page === 7 ? `url(${backgroundWhite})` : `url(${background})`,
            backgroundSize: "cover",
            display: "flex",
            position: this.state.page === 7 ? 'relative' : 'static',
            justifyContent: this.state.page === 7 ? 'center' : "flex-end",
            alignItems: "center",
            padding: '20px 0px'
          }}
        >
          {this.state.page === 1 && <BusinessType handleChange={this.handleBusinessTypeChange} changePage={this.changePage} />}
          {this.state.page === 2 && <BusinessInformation handleChange={this.handleBusinessInformationChange} changePage={this.changePage} />}
          {this.state.page === 3 && <ContactInformation handleChange={this.handleContactInformationChange} changePage={this.changePage} />}
          {this.state.page === 4 && <Categories handleChange={this.handleCategoryChange} changePage={this.changePage} getCategories={this.getCategories} arrayCategories={this.state.arrayCategories} getSubcategories={this.getSubcategories} arraySubcategories={this.state.arraySubcategories}/>}
          {this.state.page === 5 && <Products handleChange={this.handleProductsChange} changePage={this.changePage} getProducts={this.getProducts} arrayProducts={this.state.arrayProducts}/>}
          {this.state.page === 6 && <ProfilePicture handleChange={this.handleProfilePictureChange} changePage={this.changePage} />}
          {this.state.page === 7 && <CongratulationScreen navigation={this.props.navigation}/> }
        </Paper>
      </Box>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
