import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { toast } from "react-toastify";

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    recommendationData: any;
    selectedFollowingId: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SuggestionPageWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getRecommendationApiCallId: string = '';
    createFollowersApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            recommendationData: [],
            selectedFollowingId: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getRecommendationApiCallId) {
                if (responseJson) {
                    this.setState({
                        loading: false,
                        recommendationData: responseJson.data
                    })
                }
            } else if (apiRequestCallId === this.createFollowersApiCallId) {
                this.setState({ loading: false });
                if (responseJson.meta) {
                    toast.success(responseJson.meta.message);
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 1500);
                }
            }
        }

        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getRecommendationAPIDetails();
        // Customizable Area End
    }

    // Customizable Area Start

    handleFollow = (item: any) => {
        this.setState(prevState => ({
            selectedFollowingId: prevState.selectedFollowingId.includes(item.id)
                ? prevState.selectedFollowingId.filter((id: any) => id !== item.id)
                : [...prevState.selectedFollowingId, item.id]
        }));
    }

    getRecommendationAPIDetails() {
        this.setState({ loading: true })
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getRecommendationApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_informations/get_recommendation");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createFollower = () => {
        this.setState({ loading: false });
        const header = { token: this.state.token };
        const formData = new FormData()
        formData.append("account_id", this.state.selectedFollowingId.join(','))
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createFollowersApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_followers/follows");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
