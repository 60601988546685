import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Badge, Box, FormControl, Menu, MenuItem, OutlinedInput, Typography } from "@material-ui/core";
import { imgSearch } from "../../blocks/landingpage/src/assets";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
interface myProps {
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    borderBottom: '1px solid #030F2D1A',
    background: '#FFFFFF',
    width: '100%',
    padding: '0px 32px'
  },
  searchInput: {
    height: '46px',
    maxWidth: '380px',
    padding: '12px 16px',
    borderRadius: '18px',
    gap: '8px',
    boxShadow: '0px 10px 20px 0px #DEDEDE57',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'transparent'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: 'transparent'
    },
  },
  badgeIcon: {
    "& .MuiBadge-colorSecondary": {
      backgroundColor: '#2BAA61',
      fontWeight: 700,
      color: '#FFFFFF'
    }
  },
  profileBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
}));

export default function AppHeader(props: myProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Typography style={{ fontSize: '24px', fontWeight: 500, color: '#030F2D' }}>Hey Ryan!</Typography>
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
        <FormControl variant="outlined">
          <OutlinedInput
            placeholder="Search"
            // value={searchKey}
            // onChange={this.searchChange}
            className={classes.searchInput}
            startAdornment={<img src={imgSearch} alt="search" />}
          />
        </FormControl>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px', width: '48px', background: '#F6F6F6', borderRadius: '24px' }}>
          <Badge badgeContent={1} className={classes.badgeIcon} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </Box>
        <Box className={classes.profileBox} onClick={handleMenu}>
          <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-ihS3fAvKqH5iJ5R3j5KR7VXd5aoYVlbk5cmTyy-y0vU90nCYkWvsRXdQfAy02AgoZ8k&usqp=CAU" />
          <KeyboardArrowDownIcon />
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>View Profile</MenuItem>
          <MenuItem onClick={handleClose}>Settings</MenuItem>
          <MenuItem onClick={handleClose}>Help center</MenuItem>
          <MenuItem onClick={handleClose}>Sign out</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}
