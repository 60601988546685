import { Button, Typography } from "@material-ui/core";
import React from "react";
import CustomStepper from "./CustomStepper";
import ResponsiveBox from "./ResponsiveBox";
import InsideDiv from "./InsideDiv";
import { Formik } from "formik";

const image = require('../../blocks/email-account-registration/assets/image.png');
const addImage = require('../../blocks/email-account-registration/assets/addImage.png');


interface ProfilePicProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  changePage: (page: number) => void,
}

const ProfilePicture = ({ handleChange: handleProfilePicChange, changePage }: ProfilePicProps) => {
  let fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  const handleAddImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <ResponsiveBox data-testid='Box' style={{ height: 'fit-content'}} >
      <InsideDiv className="country-select">
        <CustomStepper data-testid='CustomStepper' activeStep={6} maxSteps={6} />
        <Typography
          data-testid="TitleText"
          style={{
            fontFamily: "HK Grotesk",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "140%",
            marginTop: '2rem',
          }}
        >
          Add your Profile Picture
        </Typography>
        <Typography
          data-testid="DescriptionText"
          style={{
            color: "var(--Subtext, #999BAC)",
            fontFamily: "HK Grotesk",
            fontSize: "19px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "140%",
            marginTop: "2px",
            marginBottom: "46px"
          }}
        >
          Add a profile picture in JPG or PNG format
        </Typography>
        <Formik data-testid='Formik' initialValues={{ avatar: null, preview: image }} onSubmit={({ avatar }) => {
          if (avatar) {
            // @ts-ignore
            handleProfilePicChange({ avatar });
          }
          changePage(7)
        }}>
          {({
            values,
            handleSubmit,
            setFieldValue,
          }) => (
            <form data-testid='Form' onSubmit={handleSubmit}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '146px', marginTop: '113px', marginBottom: '113px' }}>
                <div style={{ position: 'relative' }}>
                  <img src={values.preview} style={{ height: '146px', width: '146px' }} />
                  <img
                    src={addImage}
                    style={{ height: '38px', width: '38px', cursor: 'pointer', position: 'absolute', right: '0', bottom: '0' }}
                    alt="Add Image"
                    onClick={handleAddImageClick}
                  />
                  <input
                    type="file"
                    data-test-id="inputImage"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event && event.currentTarget && event.currentTarget.files) {
                        setFieldValue("avatar", event.currentTarget.files[0] as Blob);
                        setFieldValue("preview", URL.createObjectURL(event.currentTarget.files[0]))
                      }
                    }}
                  />
                </div>
              </div>
              <Button
                data-testid="BUTypeNextButton"
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  background: !values.avatar ? "#999BAC" : "#5D248A",
                  color: "var(--basic-white, #FFF)",
                  fontFamily: "HK Grotesk",
                  fontWeight: 700,
                  marginTop: "7rem",
                  textTransform: "capitalize",
                }}
                disabled={!values.avatar}
                type="submit"
              >
                Continue
              </Button>
            </form>
          )}
        </Formik>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: '40px'
        }}>
          <button 
            style={{
              border: '0px',
              color: 'var(--primary-500, #6A299F)',
              textAlign: 'center',
              fontFamily: 'HK Grotesk',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '140%',
              background: 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => {
              changePage(7);
            }}
          >
            Skip for now
          </button>
        </div>
      </InsideDiv>
    </ResponsiveBox>
  )
}
/* image_02 */


export default ProfilePicture;