import React from "react";
// Customizable Area Start
import LandingPageController, {
  Props
} from "./LandingPageController";

import { StyleRules, withStyles } from "@material-ui/core/styles";

import Loader from "../../../components/src/Loader.web";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import LandingPageHeader from "../../../components/src/LandingPageHeader.web";
import LandingPageFooter from "../../../components/src/LandingPageFooter.web";
import { img02, img03, img04, imgBg, imgBottom, imgLanding, imgPoint, imgTop } from "./assets";
// Customizable Area End

const styles: StyleRules = {
  // Customizable Area Start
  root: {
    boxShadow: 'none',
    borderRadius: '0px',
  },
  topBox: {
    backgroundImage: `url(${imgTop})`,
    maxHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  topContainor: {
    display: 'flex',
    maxWidth: '90vw',
    flexDirection: 'column',
    gap: '5vh'
  },
  bottomBox: {
    backgroundImage: `url(${imgBottom})`,
    minHeight: '100vh',
    width: '100%',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondBox: {
    backgroundColor: '#F9F9F9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  secondContainer: {
    display: 'flex',
    flex: 1,
    gap: '5vh',
    padding: '5vh 0',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minHeight: '794px',
    maxWidth: '90vw'
  },
  topHeading: {
    fontSize: '72px',
    fontWeight: 700,
    textAlign: 'center',
    color: '#FFFFFF',
    '@media (max-width: 600px)': {
      fontSize: '34px'
    }
  },
  heading: {
    fontSize: '54px',
    fontWeight: 800,
    textAlign: 'center',
    color: '#030F2D',
    '@media (max-width: 600px)': {
      fontSize: '34px'
    }
  },
  mainInfoBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '16px'
  },
  infoBox: {
    height: '245px',
    maxWidth: '376px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    border: '1px solid #999BAC26',
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF)',
    borderRadius: '12px',
    padding: '16px',
  },
  middleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  descriptionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '@media (max-width: 1175px)': {
      justifyContent: 'center'
    }
  },
  startedBtn: {
    height: '52px',
    backgroundColor: '#FFFFFF',
    color: '#6A299F',
    whiteSpace: 'nowrap',
    borderRadius: '12px',
    padding: '10px 16px',
    fontSize: '23px',
    fontWeight: 700,
    textTransform: 'none',
    margin: '25px 0px',
    '&:hover': {
      backgroundColor: '#FFFFFF'
    }
  }
  // Customizable Area End
}

export class LandingPageWeb extends LandingPageController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { loading } = this.state;
    const { classes } = this.props;

    return (
      <Paper className={classes.root} >
        <Loader loading={loading} />
        <Box className={classes.topBox} >
          <LandingPageHeader />
          <Box className={classes.topContainor}>
            <Typography className={classes.topHeading}>Empowering business worldwide</Typography>
            <Box>
              <Typography style={{ fontSize: '24px', textAlign: 'center', color: '#FFFFFF' }}>Bridging Gaps, Creating Opportunities.</Typography>
              <Typography style={{ fontSize: '24px', textAlign: 'center', color: '#FFFFFF' }}>Connect Globally, Thrive Locally with Our Innovative Business Network App!</Typography>
            </Box>
            <img src={imgLanding} style={{ zIndex: 0 }} />
          </Box>
        </Box>
        <Box className={classes.secondBox} >
          <Box className={classes.secondContainer}>
            <Typography style={{ fontSize: '23px', fontWeight: 500, textAlign: 'center', color: '#40AE8B' }}>Why Catoo?</Typography>
            <Typography className={classes.heading} >Transforming connections into collaborations</Typography>
            <Typography style={{ fontSize: '24px', textAlign: 'center', color: '#999BAC' }}>Elevate your business with our cutting-edge B2B networking app. Seamlessly connect with industry leaders, expand your network, and unlock endless opportunities. Empowering businesses through meaningful connections. Join us and fuel your success journey today!</Typography>
            <Box className={classes.mainInfoBox}>
              {this.whyCatoo.map((item: any) => (
                <Box className={classes.infoBox}>
                  <img src={item.img} alt="logo" />
                  <Typography style={{ fontSize: '23px', fontWeight: 600, color: '#000000' }} >{item.title}</Typography>
                  <Typography style={{ fontSize: '19px', fontWeight: 500, color: '#999BAC' }} >{item.descreption}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box className={classes.middleBox} >
          <Box style={{ maxWidth: '90vw', margin: '50px 0px', flex: 1 }}>
            {this.whyCatooDetails.map((item: any, index: number) => {
              return (
                index % 2 ?
                  <Box className={classes.descriptionBox}>
                    <Box key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '8% 0', maxWidth: '550px' }}>
                      <Typography style={{ fontSize: '23px', fontWeight: 500, color: '#40AE88' }}>{item.subtitle}</Typography>
                      <Typography className={classes.heading} style={{ color: '#030F2D', textAlign: 'start' }}>{item.title}</Typography>
                      {item.points.map((point: any) => (
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={imgPoint} style={{ marginRight: '1%' }} />
                          <Typography>{point}</Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box key={index + 1} style={{ display: 'flex', alignItems: 'center', margin: '8% 0', maxWidth: '550px' }}>
                      <img src={item.img} style={{ zIndex: 1, position: 'relative' }}></img>
                      <img src={imgBg} style={{ position: 'absolute' }} />
                    </Box>
                  </Box>
                  :
                  <Box className={classes.descriptionBox}>
                    <Box key={index + 1} style={{ display: 'flex', alignItems: 'center', margin: '8% 0', maxWidth: '550px' }}>
                      <img src={item.img} style={{ zIndex: 1, position: 'relative' }}></img>
                      <img src={imgBg} style={{ position: 'absolute' }} />
                    </Box>
                    <Box key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '8% 0', maxWidth: '550px' }}>
                      <Typography style={{ fontSize: '23px', fontWeight: 500, color: '#40AE88' }}>{item.subtitle}</Typography>
                      <Typography className={classes.heading} style={{ color: '#030F2D', textAlign: 'start' }}>{item.title}</Typography>
                      {item.points.map((point: any) => (
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={imgPoint} style={{ marginRight: '1%' }} />
                          <Typography>{point}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
              )
            })}
          </Box>
        </Box>
        <Box className={classes.bottomBox} >
          <Box className={classes.secondContainer}>
            <Typography className={classes.heading} style={{ color: '#FFFFFF' }} >Embark on Success: Start Your Journey with Our App Today</Typography>
            <Button className={classes.startedBtn} onClick={() => this.props.navigation.navigate("CountryCodeSelector")}>Get started</Button>
            <Box style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <img src={img03} style={{ position: 'absolute', width: '36%', left: "10vw" }} />
              <img src={img02} style={{ position: 'relative', width: '50%', zIndex: 1 }} />
              <img src={img04} style={{ position: 'absolute', width: '36%', right: '10vw' }} />
            </Box>
          </Box>
        </Box>
        <LandingPageFooter />
      </Paper>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(LandingPageWeb);
// Customizable Area End