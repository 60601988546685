import { Button, Typography } from "@material-ui/core";
import React from "react";
import CustomStepper from "./CustomStepper";
import { Formik } from "formik";
import CustomRadioButton from "./CustomRadioButton";
import CustomHr from "./CustomHr";
import ResponsiveBox from "./ResponsiveBox";
import InsideDiv from "./InsideDiv";

interface BusinessTypeProps {
  handleChange: (type: string) => void,
  changePage: (page: number) => void,
}

const BusinessType = ({ handleChange, changePage }: BusinessTypeProps) => {
  return (
    <ResponsiveBox
      data-testid='Box'
      style={{ height: 'fit-content'}}
    >
      <InsideDiv className="country-select">
        <CustomStepper data-testid='CustomStepper' activeStep={1} maxSteps={6} />
        <Typography
          data-testid="TitleText"
          style={{
            fontFamily: "HK Grotesk",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "140%",
            marginTop: '2rem',
          }}
        >
          Select your Business Type
        </Typography>
        <Typography
          data-testid="DescriptionText"
          style={{
            color: "var(--Subtext, #999BAC)",
            fontFamily: "HK Grotesk",
            fontSize: "19px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "140%",
            marginTop: "2px",
            marginBottom: "46px"
          }}
        >
          to view relevant business on the app
        </Typography>
        <Formik data-testid='Formik' initialValues={{ businessType: '' }} onSubmit={({ businessType }) => { handleChange(businessType); changePage(2) }}>
          {({
            handleChange,
            values,
            handleSubmit,
          }) => (
            <form data-testid='Form' onSubmit={handleSubmit}>
              <CustomRadioButton headText="Reseller" descriptionText="I sell online from my home" onChange={handleChange} picked={values.businessType} />
              <CustomHr />
              <CustomRadioButton headText="Retailer" descriptionText="I have a retail shop" onChange={handleChange} picked={values.businessType} />
              <CustomHr />
              <CustomRadioButton headText="Wholesaler" descriptionText="I have a wholesale store" onChange={handleChange} picked={values.businessType} />
              <CustomHr />
              <CustomRadioButton headText="Manufacturer" descriptionText="I have a manufacturing unit" onChange={handleChange} picked={values.businessType} />
              <Button
                data-testid="BUTypeNextButton"
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  background: !values.businessType ? "#999BAC" : "#5D248A",
                  color: "var(--basic-white, #FFF)",
                  fontFamily: "HK Grotesk",
                  fontWeight: 700,
                  top: "2rem",
                  textTransform: "capitalize",
                }}
                disabled={!values.businessType}
                type="submit"
              >
                Next
              </Button>
            </form>
          )}
        </Formik>
      </InsideDiv>
    </ResponsiveBox>
  )
}

export default BusinessType;
