import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  disable: boolean;
  reg1: string;
  reg2: string;
  reg3: string;
  reg4: string;
  emailValid: boolean;
  passwordValid: boolean;
  inputType: string;
  passwordColor: string;
  isPopUp: boolean;
  timeRemaining: number;
  otp: any[];
  isOtpVerified: boolean;
  isOtpFailed: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  createOtpVerifyApiCallId: any;
  resendOtpApiCallId: any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;

  checkPasswordAndEmailValidation(regexData: any) {
    if (regexData.password_validation_regexp) {
      this.passwordReg = new RegExp(
        regexData.password_validation_regexp
      );
    }

    if (regexData.password_validation_rules) {
      this.setState({
        passwordHelperText: regexData.password_validation_rules
      });
    }

    if (regexData.email_validation_regexp) {
      this.emailReg = new RegExp(regexData.email_validation_regexp);
    }
  }

  checkOTPToken(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  }

  checkCountryCode(message: Message) {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
  }

  actionOnSuccessResponse(apiRequestCallId: any, responseJson: any, errorReponse: any) {
    if (apiRequestCallId === this.validationApiCallId) {
      this.arrayholder = responseJson.data;

      if (this.arrayholder && this.arrayholder.length !== 0) {
        let regexData = this.arrayholder[0];

        this.checkPasswordAndEmailValidation(regexData)
      }
    } else if (apiRequestCallId === this.createAccountApiCallId) {
      if (!!responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
      localStorage.setItem('accountId', responseJson?.data?.id)
      localStorage.setItem('token', responseJson?.meta?.token);
    } else if ((apiRequestCallId === this.createOtpVerifyApiCallId) || (apiRequestCallId === this.resendOtpApiCallId)) {
      if (!responseJson.errors) {
        this.setState({ isOtpVerified: true })
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      disable: true,
      reg1: 'gray',
      reg2: 'gray',
      reg3: 'gray',
      reg4: 'gray',
      inputType: 'password',
      passwordColor: 'gray',
      emailValid: false,
      passwordValid: false,
      isPopUp: false,
      timeRemaining: 60,
      otp: ['', '', '', ''],
      isOtpVerified: false,
      isOtpFailed: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = /\w+/;
    this.emailReg = /\w+/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    setInterval(() => {
      this.setState({ timeRemaining: this.state.timeRemaining - 1 })
      if (this.state.timeRemaining <= 0) this.setState({ isOtpFailed: true })
    }, 1000);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.actionOnSuccessResponse(apiRequestCallId, responseJson, errorReponse)
      }
    }

    this.checkOTPToken(message)
    this.checkCountryCode(message)
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  otpApi(mode: 'Verify' | 'Resend') {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      email: this.state.email,
      otp: this.state.otp.join('')
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createOtpVerifyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      mode === 'Verify' ? 'account_block/accounts/verify_otp' : 'account_block/accounts/resend_otp'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return false;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
      let newInputType = this.state.inputType == '' ? 'password' : ''
      this.setState({ inputType: newInputType })
    }
  };

  btnSignUpProps = {
    onClick: () => {
      this.createAccount()
      this.setState({ isPopUp: true })
      this.setState({ timeRemaining: 60 })
    }
  };

  btnOtpVerifyProps = {
    onClick: () => {
      this.otpApi('Verify')
    }
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChange: (text: string) => {
      this.setState({ email: text });
      if (Boolean(text?.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/))) {
        this.setState({ disable: !this.state.passwordValid })
        this.setState({ emailValid: true })
      } else {
        this.setState({ disable: true })
        this.setState({ emailValid: false })
      }
      //@ts-ignore
      // this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputOtpWebPrpos = {
    onChange: (text: any[]) => {
      this.setState({ otp: text });
      if (this.state.otp.length == 4) {
        this.setState({ disable: false })
      } else {
        this.setState({ disable: true })
      }
      //@ts-ignore
      // this.txtInputEmailPrpos.value = text;
    },
  };


  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputOtpVerificationPrpos = {
    ...this.txtInputOtpWebPrpos,
    // keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  checkEmptyPassword = (text: string) => {
    if (text == '') {
      this.setState({ reg1: 'gray' })
      this.setState({ reg2: 'gray' })
      this.setState({ reg3: 'gray' })
      this.setState({ reg4: 'gray' })
    }
  }

  checkPasswordLength(text: string) {
    if (text.length >= 8) {
      this.setState({ reg1: 'green' })
      return false;
    } else {
      this.setState({ reg1: 'red' })
      return true;
    }
  }

  checkUpperCase(text: string, disableBtn: boolean) {
    if (Boolean(text?.match(/[A-Z]/))) {
      this.setState({ reg2: 'green' })
      return disableBtn
    } else {
      this.setState({ reg2: 'red' })
      return true
    }
  }

  checkLowerCase(text: string, disableBtn: boolean) {
    if (Boolean(text?.match(/[a-z]/))) {
      this.setState({ reg3: 'green' })
      return disableBtn
    } else {
      this.setState({ reg3: 'red' })
      return true
    }
  }

  checkRegex(text: string, disableBtn: boolean) {
    const pattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|]/;
    if (pattern.test(text)) {
      this.setState({ reg4: 'green' })
      return disableBtn
    } else {
      this.setState({ reg4: 'red' })
      return true
    }
  }


  txtInputPasswordProps = {
    onChange: (text: string) => {
      this.setState({ password: text });
      let disableBtn = true
      this.checkEmptyPassword(text)
      disableBtn = this.checkPasswordLength(text)
      disableBtn = this.checkUpperCase(text, disableBtn)
      disableBtn = this.checkLowerCase(text, disableBtn)
      disableBtn = this.checkRegex(text, disableBtn)
      if (disableBtn) {
        this.setState({ passwordColor: 'green' })
      } else {
        this.setState({ passwordColor: 'red' })
      }
      let disabled = this.state.emailValid && !disableBtn
      this.setState({ disable: !disabled })
      this.setState({ passwordValid: !disableBtn })
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
