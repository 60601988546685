import React, { useState } from "react";

interface ProductProps {
  selected?: boolean;
  text: string;
  type: string;
  products: Array<{ type: string, text: string }>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
}

const Product = ({ text, type, selected = false, products, setFieldValue }: ProductProps) => {
  const [active, setActive] = useState<boolean>(selected);

  const handleClick = () => {
    setActive(!active);
    const element = { text, type };
    if (active) {
      const tmp = products.filter((e) => e.text !== text)
      setFieldValue('products', tmp)
    } else {
      products.push(element)
      setFieldValue('products', products)
    }
  }

  const style = {
    cursor: 'pointer',
    display: 'inline-block',
    width: 'auto',
    height: 'auto',
    borderRadius: '1000px',
    border: active ? '1px solid #CFADEA' : '1px solid #F3EAFA',
    background: active ? '#F3EAFA' : '#FFF',
    fontFamily: 'HK Grotesk',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    color: active ? 'var(--primary-500, #6A299F)' : 'var(--Subtext, #999BAC)',
    padding: '7px 10px 7px 10px',
    margin: '.25rem',
  }

  return <button name={text} onClick={handleClick} style={style} type="button">
    {text}
  </button>
}

export default Product;
