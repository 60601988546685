import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  TextField,
} from "@material-ui/core";
const loginbackground = require('../assets/background.png');
import { createTheme } from "@material-ui/core/styles";
import { Email, Visibility, VisibilityOff } from "@material-ui/icons";
import ResponsiveBox from "../../../components/src/ResponsiveBox";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Paper
        data-testid='Background'
        style={{
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${loginbackground})`,
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <Loader loading={this.state.loading} />
        <ResponsiveBox style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '0 4rem' }}>
          <div>
            <Typography
              data-testid='CreateAccountDescription'
              style={{ color: '#030F2D', fontSize: '40px', fontWeight: 700, }}
            >
              Welcome Back!
            </Typography>
            <Typography
              data-testid='CreateAccountDescription'
              style={{ color: '#999BAC', fontSize: '19px', fontWeight: 500, }}
            >
              Sign in to your profile
            </Typography>
          </div>
          <div>
            <div>
              <Typography style={{ color: '#000000', fontSize: '16px', marginBottom: '10px', fontWeight: 500 }} >
                Email
              </Typography>
              <TextField
                data-testid='txtInputEmail'
                name="email"
                variant="outlined"
                placeholder='Email'
                fullWidth
                value={this.state.email}
                onChange={(e) => this.setEmail(e.target.value)}
                style={{ marginBottom: '16px', borderRadius: '12px', color: '#999BAC', fontSize: '16px' }}
                InputProps={{
                  style: {
                    borderRadius: '15px'
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
              {
                this.state.invalidEmail ?
                  <p style={{ color: 'red', margin: '3px', fontSize: "14px", display: 'flex', padding: '2px' }}>Invalid Email</p>
                  :
                  null}
            </div>
            <div>
              <Typography style={{ color: '#000000', fontSize: '16px', marginBottom: '10px', fontWeight: 500 }} >
                Password
              </Typography>
              <TextField
                data-testid='txtInputPassword'
                placeholder='Password'
                variant="outlined"
                type={this.state.enablePasswordField ? "password" : "text"}
                fullWidth
                value={this.state.password}
                onChange={(e) => this.setPassword(e.target.value)}
                style={{ marginBottom: '16px', borderRadius: '12px', color: '#999BAC', fontSize: '16px' }}
                InputProps={{
                  style: {
                    borderRadius: '15px',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-test-id="showPassword"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                      >
                        {this.state.enablePasswordField ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {this.state.incorrectPassword ?
                <p style={{ color: 'red', margin: '3px', fontSize: "14px", display: 'flex', padding: '2px' }}>Wrong Password</p>
                : null
              }
            </div>
            <div style={{ textAlign: 'end' }}>
              <a href="/ForgotPasswordEmail" style={{ fontSize: "16px", textDecoration: 'none', color: "#6A299F", fontWeight: 700 }}>Forgot Password ?</a>
            </div>
          </div>
          <Button
            data-test-id='btnEmailLogIn'
            onClick={() => this.doEmailLogIn()}
            style={{
              display: 'flex',
              width: '100%',
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '12px',
              background: this.state.password.length >= 8 && this.state.email.length > 0 ? '#5D248A' : '#999BAC',
              color: 'var(--basic-white, #FFF)',
              fontFamily: 'HK Grotesk',
              fontWeight: 700,
              textTransform: 'capitalize',
              marginTop: '2rem',
              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
            }}
            disabled={!(this.state.password.length >= 8 && this.state.email.length > 0)}
          >
            Next
          </Button>
          <Typography style={{ color: '#999BAC', fontSize: '19px', fontWeight: 500, textAlign: 'center' }} >
            Don't have an account? <a href="/EmailAccountRegistration" style={{ fontSize: "19px", textDecoration: 'none', color: "#6A299F", fontWeight: 700, textAlign: "end" }}>Sign Up</a>
          </Typography>
        </ResponsiveBox>
      </Paper>
    );
    // Customizable Area End
  }
}
