import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import * as Yup from 'yup';
import CustomStepper from "./CustomStepper";
import { Formik } from "formik";
import TextLabel from "./TextLabel";
import TextInput from "./TextInput";
import ErrorText from "./ErrorText";
import InsideDiv from "./InsideDiv";
import ResponsiveBox from "./ResponsiveBox";

interface BusinessInformationProps {
  handleChange: (data: {
    businessName: string;
    establishedYear: string;
    streetAddress: string;
    city: string;
    state: string;
    country: string;
    pinCode: string;
  }) => void,
  changePage: (page: number) => void,
}

const BusinessInformation = ({ handleChange, changePage }: BusinessInformationProps) => {
  const BusinessInformationFormSchema = Yup.object().shape({
    businessName: Yup.string()
      .min(2, "Too Short!")
      .max(10, "Too Long!")
      .required("Please enter your business name."),
    establishedYear: Yup.string()
      .min(4, "Too Short!")
      .max(4, "Too Long!")
      .required("Please enter your established year."),
    streetAddress: Yup.string()
      .min(2, "Too Short!")
      .required("Please enter your street address."),
    city: Yup.string()
      .min(2, "Too Short!")
      .required("Please enter your city."),
    state: Yup.string()
      .min(2, "Too Short!")
      .required("Please enter your state."),
    country: Yup.string()
      .min(2, "Too Short!")
      .required("Please enter your country."),
    pinCode: Yup.string()
      .min(4, "Too Short!")
      .max(8, "Too Long!")
      .required("Please enter your pin code."),
  });

  return (
    <ResponsiveBox
      data-testid='Box'
      style={{ height: 'fit-content'}}
    >
      <InsideDiv className="country-select">
        <CustomStepper activeStep={2} maxSteps={6} />
        <Typography
          data-testid="TitleText"
          style={{
            fontFamily: "HK Grotesk",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "140%",
            marginTop: '2rem',
            marginBottom: '2.5rem'
          }}
        >
          Business Information
        </Typography>
        <Formik validateOnMount data-testid='Formik' initialValues={{
          businessName: '',
          establishedYear: '',
          streetAddress: '',
          city: '',
          state: '',
          country: '',
          pinCode: '',
        }} validationSchema={BusinessInformationFormSchema} onSubmit={(data: {
          businessName: string;
          establishedYear: string;
          streetAddress: string;
          city: string;
          state: string;
          country: string;
          pinCode: string;
        }) => { handleChange(data); changePage(3) }}>
          {({
            handleChange: handleFormChange,
            handleSubmit,
            errors,
            isValid,
            touched
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <div style={{ width: '48%' }}>
                  <TextLabel text="Business name" />
                  <TextInput isInvalid={!!(touched.businessName && errors.businessName)} name="businessName" onChange={handleFormChange} />
                  {touched.businessName && errors.businessName && (
                    <ErrorText text={errors.businessName} />
                  )}
                </div>
                <div style={{ width: '48%' }}>
                  <TextLabel text="Established year" />
                  <TextInput isInvalid={!!(touched.establishedYear && errors.establishedYear)} name="establishedYear" onChange={handleFormChange} />
                  {touched.establishedYear && errors.establishedYear && (
                    <ErrorText text={errors.establishedYear} />
                  )}
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <TextLabel text="Area / Street address" />
                <TextInput isInvalid={!!(touched.streetAddress && errors.streetAddress)} name="streetAddress" onChange={handleFormChange} />
                {touched.streetAddress && errors.streetAddress && (
                  <ErrorText text={errors.streetAddress} />
                )}
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <div style={{ width: '48%' }}>
                  <TextLabel text="Town / City" />
                  <TextInput isInvalid={!!(touched.city && errors.city)} name="city" onChange={handleFormChange} />
                  {touched.city && errors.city && (
                    <ErrorText text={errors.city} />
                  )}
                </div>
                <div style={{ width: '48%' }}>
                  <TextLabel text="State / Country" />
                  <TextInput isInvalid={!!(touched.state && errors.state)} name="state" onChange={handleFormChange} />
                  {touched.state && errors.state && (
                    <ErrorText text={errors.state} />
                  )}
                </div>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <div style={{ width: '48%' }}>
                  <TextLabel text="Country" />
                  <TextInput isInvalid={!!(touched.country && errors.country)} name="country" onChange={handleFormChange} />
                  {touched.country && errors.country && (
                    <ErrorText text={errors.country} />
                  )}
                </div>
                <div style={{ width: '48%' }}>
                  <TextLabel text="Pin-code" />
                  <TextInput isInvalid={!!(touched.pinCode && errors.pinCode)} name="pinCode" onChange={handleFormChange} />
                  {touched.pinCode && errors.pinCode && (
                    <ErrorText text={errors.pinCode} />
                  )}
                </div>
              </div>
              <Button
                data-testid="BUInfoNextButton"
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  background: !isValid ? "#999BAC" : "#5D248A",
                  color: "var(--basic-white, #FFF)",
                  fontFamily: "HK Grotesk",
                  fontWeight: 700,
                  top: "3rem",
                  textTransform: "capitalize",
                }}
                disabled={!isValid}
                type="submit"
              >
                Next
              </Button>
            </form>
          )}
        </Formik>
      </InsideDiv>
    </ResponsiveBox>
  )
}

export default BusinessInformation;
