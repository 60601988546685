// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web"
import ForgotPasswordEmail from "../../blocks/forgot-password/src/ForgotPasswordEmail.web"
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web'
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import FAQsPageWeb from "../../blocks/landingpage/src/FAQsPage.web";
import HomeScreen from "../../components/src/HomeScreen";
import SideBar from "../../components/src/SideBar.web"
import CustomThemeProvider from "../../components/src/CustomThemeProvider"

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BusinessAndContacts from '../../blocks/email-account-registration/src/BusinessAndContacts.web'
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector.web";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web"
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web'
import SuggestionPageWeb from '../../blocks/email-account-registration/src/SuggestionPage.web'
import LandingPageWeb from '../../blocks/landingpage/src/LandingPage.web'
import Contactus from '../../blocks/contactus/src/Contactus.web'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const routeMap = {
  Home: {
    component: LandingPageWeb,
    path: '/',
    exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },

  BusinessType: {
    component: BusinessAndContacts,
    path: '/BusinessType'
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: '/EmailAccountLogin'
  },

  SuggestionPage: {
    component: SuggestionPageWeb,
    path: '/SuggestionPage'
  },

  LandingPage: {
    component: LandingPageWeb,
    path: '/LandingPage'
  },

  FAQsPage: {
    component: FAQsPageWeb,
    path: '/FAQsPage'
  },

  ContactUs: {
    component: Contactus,
    path: '/ContactUs'
  },

  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  
  ForgotPasswordEmail :{
    component: ForgotPasswordEmail,
    path: `/ForgotPasswordEmail`
  },

  ForgotPassword :{
    component: ForgotPassword,
    path: `/ForgotPassword`
  },

  EmailAccountLogin :{
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLogin'
  },

  SideBar: {
    component: SideBar,
    path: '/SideBar'
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
 
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <CustomThemeProvider>
      <View style={{ height: '100vh', width: '100vw', overflowX: 'hidden' }}>
        <ToastContainer/>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
      </CustomThemeProvider>
    );
  }
}

export default App;
