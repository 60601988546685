import React, { ChangeEvent } from "react";
import { View, TouchableOpacity } from "react-native";
import { Box, Paper, Typography, Button, TextField, InputAdornment } from "@material-ui/core";
import { Email, Visibility, VisibilityOff, Close, Check } from "@material-ui/icons";
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import ResponsiveBox from "../../../components/src/ResponsiveBox";
import InsideDiv from "../../../components/src/InsideDiv";
const background = require('../assets/background.png');
const checkbox = require('../assets/image_checkbox.png');

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  // Customizable Area Start

  handleEmailChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    this.txtInputEmailPrpos.onChange(target.value)
  }

  handlePasswordChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    this.txtInputPasswordProps.onChange(target.value)
  }

  goToBusinessType = () => {
    setTimeout(() => this.props.navigation.navigate('BusinessType'), 2000)
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start

    const getIcons = (text: string) => {
      if (text == 'grey') {
        return
      } else if (text == 'green') {
        return (<Check fontSize="small" />)
      } else if (text == 'red') {
        return (<Close fontSize="small" />)
      }
    }
    const handleOTPChange = (index: number, value: any) => {
      const newOTP = [...this.state.otp];
      newOTP[index] = value.target.value;
      if ((!!(value.target.value?.match(/^(?:\d|)$/)))) {
        this.setState({ otp: newOTP });
      }
    };

    return (
      <View style={{ zIndex: 99 }}>
        <Paper
          data-testid='Background'
          style={{
            width: '100vw',
            minHeight: '100vh',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            filter: this.state.isPopUp ? 'blur(7.5px)' : '',
          }}
        >
          <ResponsiveBox style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '0 4rem' }}>
            <div>
              <Typography
                data-testid='CreateAccountDescription'
                style={{ color: '#030F2D', fontSize: '40px', fontWeight: 700, }}
              >
                Create Account
              </Typography>
              <Typography
                data-testid='CreateAccountDescription'
                style={{ color: '#999BAC', fontSize: '19px', fontWeight: 500, }}
              >
                Set up your business profile
              </Typography>
            </div>
            <div>
              <div>
                <Typography style={{ color: '#000000', fontSize: '16px', marginBottom: '10px', fontWeight: 500 }} >
                  Email
                </Typography>
                <TextField
                  data-testid='EmailInput'
                  name="email"
                  variant="outlined"
                  placeholder={this.labelEmail}
                  fullWidth
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  style={{ marginBottom: '16px', borderRadius: '12px', color: '#999BAC', fontSize: '16px' }}
                  InputProps={{
                    style: {
                      borderRadius: '15px'
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <Typography style={{ color: '#000000', fontSize: '16px', marginBottom: '10px', fontWeight: 500 }} >
                  Password
                </Typography>
                <TextField
                  data-testid='PasswordInput'
                  placeholder={this.labelPassword}
                  variant="outlined"
                  type={this.state.inputType}
                  fullWidth
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  style={{ marginBottom: '16px', borderRadius: '12px', color: '#999BAC', fontSize: '16px' }}
                  InputProps={{
                    style: {
                      borderRadius: '15px',
                    },
                    endAdornment: (
                      <TouchableOpacity
                        {...this.btnPasswordShowHideProps}
                      >
                        <InputAdornment position="end">
                          {this.state.inputType == '' ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </InputAdornment>
                      </TouchableOpacity>
                    ),
                  }}
                />
              </div>
              <div>
                <Typography style={{ color: '#000000', fontSize: '16px', marginBottom: '10px', fontWeight: 500 }} >
                  Password must:
                </Typography>
                <Typography style={{ color: this.state.reg1, fontSize: "16px", display: 'flex', gap: '2px' }}>{getIcons(this.state.reg1)} be atleast 8 characters long</Typography>
                <Typography style={{ color: this.state.reg2, fontSize: "16px", display: 'flex', gap: '2px' }}>{getIcons(this.state.reg2)} include atleast 1 uppercase letter (A-Z)</Typography>
                <Typography style={{ color: this.state.reg3, fontSize: "16px", display: 'flex', gap: '2px' }}>{getIcons(this.state.reg3)} include atleast 1 lowercase letter (a-z)</Typography>
                <Typography style={{ color: this.state.reg4, fontSize: "16px", display: 'flex', gap: '2px' }}>{getIcons(this.state.reg4)} include atleast 1 special character (@,#, $ etc)</Typography>
              </div>
            </div>
            <Button
              data-testid='NextButton'
              style={{
                display: 'flex',
                width: '100%',
                padding: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
                background: this.state.disable ? '#999BAC' : '#5D248A',
                color: 'var(--basic-white, #FFF)',
                fontFamily: 'HK Grotesk',
                fontWeight: 700,
                textTransform: 'capitalize',
                marginTop: '2rem',
                boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
              }}
              disabled={this.state.disable}
              {...this.btnSignUpProps}
            >
              Next
            </Button>
            <Typography style={{ color: '#999BAC', fontSize: '19px', fontWeight: 500, textAlign: 'center' }} >
              Already have an account? <a href="/EmailAccountLogin" style={{ fontSize: "19px", textDecoration: 'none', color: "#6A299F", fontWeight: 700, textAlign: "end" }}>Sign In</a>
            </Typography>
          </ResponsiveBox>
        </Paper>
        {(this.state.isPopUp) &&

          (
            <Box data-testid='OTPPopUp' style={{
              textAlign: 'center',
              overflow: 'hidden auto',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.40)',
              zIndex: 1000,
            }}

            >
              {!this.state.isOtpVerified ? (
                <Box
                  data-testid='OTPInsideBlock'
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: '#FFFEFE',
                    padding: '20px',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
                    textAlign: 'center',
                    height: '67vh',
                    width: '29%'
                  }}
                >
                  <>
                    <div style={{ float: 'right' }}>
                      <TouchableOpacity onPress={() => this.setState({ isPopUp: false })}>
                        <Close fontSize="small" />
                      </TouchableOpacity>
                    </div>
                    <Typography
                      data-testid='VerificationText'
                      style={{
                        color: 'black',
                        fontFamily: 'HK Grotesk',
                        fontSize: '40px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '7%',
                        paddingTop: '15px',
                        lineHeight: '56px'
                      }}
                    >
                      Verification
                    </Typography>

                    <Typography

                      style={{
                        flexDirection: 'row', alignItems: 'center', marginTop: '13%'

                      }}
                    >
                      {this.state.otp.map((digit, index) => (
                        <TextField
                          data-testid={`OTPInput${index}`}
                          key={index}
                          style={{
                            width: '40px',
                            border: 'none',
                            borderBlockEnd: '0px solid #6A299F',
                            margin: '7px',
                            fontSize: '38px',
                            color: '#6A299F',
                          }}
                          InputProps={{
                            style: {
                              fontSize: '38px',
                              color: '#6A299F',
                              textAlign: 'center',
                              alignItems: 'center'
                            }
                          }}
                          // disabled={this.state.timeRemaining < 0}
                          disabled={false}
                          onChange={(text) => handleOTPChange(index, text)}
                          value={digit}

                        />
                      ))}
                    </Typography>
                    <div
                      style={{
                        color: 'gray',
                        textAlign: 'center',
                        marginTop: '3rem'
                      }}
                    >
                      <p>Enter the OTP code contained in the Email we sent you</p>
                      {!this.state.isOtpFailed ? <p>OTP verification code exists: {this.state.timeRemaining}s</p> :
                        <p style={{ color: 'red' }}>OTP verification timed out</p>}
                      <TouchableOpacity
                        data-testid='OTPResend'
                        onPress={() => this.otpApi('Resend')}
                        disabled={this.state.timeRemaining > 0}
                      >
                        <Typography
                          data-testid='ResendOTP'
                          style={{
                            color: '#6A299F',
                            fontWeight: 'bold'
                          }}
                        >
                          Resend OTP
                        </Typography>
                      </TouchableOpacity>
                    </div>
                    <Button
                      data-testid='OTPSubmit'
                      style={{
                        width: '85%',
                        height: '6vh',
                        padding: '1rem',
                        alignItems: 'center',
                        textAlign: 'center',
                        borderRadius: '12px',
                        color: 'var(--basic-white, #FFF)',
                        fontFamily: 'HK Grotesk',
                        fontWeight: 700,
                        textTransform: 'capitalize',
                        marginTop: '8%',
                        marginLeft: '2%',
                        background: (this.state.otp.join('').length < 4) ? '#999BAC' : '#5D248A',
                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
                      }}
                      disabled={this.state.otp.join('').length < 4}
                      {...this.btnOtpVerifyProps}
                    >
                      Submit
                    </Button>
                  </>
                </Box>

              ) :
                (<>
                  <Box
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      background: '#FFFEFE',
                      padding: '20px',
                      borderRadius: '12px',
                      boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
                      textAlign: 'center',
                      height: '42vh',
                      width: '27%'
                    }}
                  >
                    <img src={checkbox} style={{
                      marginTop: "23px",
                    }} />
                    <Typography style={{ marginTop: "39px", fontSize: '30px' }}>
                      Email Address
                    </Typography>
                    <Typography style={{ fontSize: '30px' }}>
                      Verified !
                    </Typography>
                    {this.goToBusinessType()}
                  </Box>
                </>
                )}
            </Box>
          )}
      </View>
    );
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End
}