import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, CircularProgress, CircularProgressProps, Paper, Typography } from "@material-ui/core";
import { imgCommunity, imgHome, imgMessage, imgNetwork, imgSetting, imgLogo, imgRedirect, imgHome1, imgNetwork1, imgMessage1, imgCommunity1, imgSetting1 } from "./assets";
import AppHeader from "./AppHeader.web";

interface myProps {
}

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: 'none',
        borderRadius: '0px',
        maxWidth: '315px',
        width: '100%',
        background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderRight: '1px solid #030F2D1A'
    },
    navigationBox: {
        display: 'flex',
        padding: '16px 32px',
        gap: '10px',
        cursor: 'pointer'
    },
    navigationTxt: {
        fontSize: '16px',
        color: '#999BAC'
    },
    profileBox: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        boxShadow: '0px 4px 24px 0px #E4E4E4CC',
        borderRadius: '18px',
        width: '100%',
        maxWidth: '246px',
        minHeight: '70px',
        padding: '0px 8px'
    },
    profileHeading: {
        fontSize: '12px',
        color: '#999BAC'
    },
    profileTxt: {
        fontSize: '12px',
        color: '#000000',
        fontWeight: 600
    }
}));

const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number },
) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" style={{ height: '70px', width: '70px', color: '#9246CF', transform: 'rotate(0deg)' }} {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#999BAC' }}>73%</Typography>
            </Box>
        </Box>
    );
}

export default function SideBar(props: myProps) {
    const classes = useStyles();
    return (
        <Box style={{ display: 'flex' }}>
            <Paper className={classes.root}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '32px', minHeight: '325px', justifyContent: 'space-between' }}>
                    <img src={imgLogo} style={{ alignSelf: 'baseline' }} />
                    <Box className={classes.profileBox}>
                        <Avatar src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-ihS3fAvKqH5iJ5R3j5KR7VXd5aoYVlbk5cmTyy-y0vU90nCYkWvsRXdQfAy02AgoZ8k&usqp=CAU' />
                        <Box>
                            <Typography className={classes.profileHeading}>Products</Typography>
                            <Typography className={classes.profileTxt}>124</Typography>
                        </Box>
                        <Typography style={{ color: '#999BAC' }} >|</Typography>
                        <Box>
                            <Typography className={classes.profileHeading}>Connections</Typography>
                            <Typography className={classes.profileTxt}>20k</Typography>
                        </Box>
                        <Typography style={{ color: '#999BAC' }} >|</Typography>
                        <Box>
                            <Typography className={classes.profileHeading}>Posts</Typography>
                            <Typography className={classes.profileTxt}>12</Typography>
                        </Box>
                    </Box>
                    <CircularProgressWithLabel value={73} />
                    <Typography style={{ fontSize: '12px', fontWeight: 500, color: '#6A299F', display: 'flex', alignItems: 'center', gap: '8px' }}>Complete your profile <img src={imgRedirect} /></Typography>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Box className={classes.navigationBox}
                        style={{
                            backgroundColor: window.location.pathname === '/' ? '#030F2D1A' : '#FFFFFF',
                            borderLeft: window.location.pathname === '/' ? '2px solid #6A299F' : '0px solid #6A299F'
                        }}>
                        <img src={window.location.pathname === '/' ? imgHome1 : imgHome} />
                        <Typography className={classes.navigationTxt}>Home</Typography>
                    </Box>
                    <Box className={classes.navigationBox}
                        style={{
                            backgroundColor: window.location.pathname === '/SideBar' ? '#030F2D1A' : '#FFFFFF',
                            borderLeft: window.location.pathname === '/SideBar' ? '2px solid #6A299F' : '0px solid #6A299F'
                        }}>
                        <img src={window.location.pathname === '/SideBar' ? imgNetwork1 : imgNetwork} />
                        <Typography className={classes.navigationTxt}>Network</Typography>
                    </Box>
                    <Box className={classes.navigationBox}
                        style={{
                            backgroundColor: window.location.pathname === '/' ? '#030F2D1A' : '#FFFFFF',
                            borderLeft: window.location.pathname === '/' ? '2px solid #6A299F' : '0px solid #6A299F'
                        }}>
                        <img src={window.location.pathname === '/' ? imgMessage1 : imgMessage} />
                        <Typography className={classes.navigationTxt}>Message</Typography>
                    </Box>
                    <Box className={classes.navigationBox}
                        style={{
                            backgroundColor: window.location.pathname === '/' ? '#030F2D1A' : '#FFFFFF',
                            borderLeft: window.location.pathname === '/' ? '2px solid #6A299F' : '0px solid #6A299F'
                        }}>
                        <img src={window.location.pathname === '/' ? imgCommunity1 : imgCommunity} />
                        <Typography className={classes.navigationTxt}>Community</Typography>
                    </Box>
                    <Box className={classes.navigationBox}
                        style={{
                            backgroundColor: window.location.pathname === '/' ? '#030F2D1A' : '#FFFFFF',
                            borderLeft: window.location.pathname === '/' ? '2px solid #6A299F' : '0px solid #6A299F'
                        }}>
                        <img src={window.location.pathname === '/' ? imgSetting1 : imgSetting} />
                        <Typography className={classes.navigationTxt}>Settings</Typography>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 32px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0px' }} >
                        <Typography style={{ fontSize: '16px', fontWeight: 500, color: '#030F2D' }} >Inbox</Typography>
                        <Typography style={{ fontSize: '16px', fontWeight: 500, color: '#999BAC' }} >See all</Typography>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }} >
                            <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-ihS3fAvKqH5iJ5R3j5KR7VXd5aoYVlbk5cmTyy-y0vU90nCYkWvsRXdQfAy02AgoZ8k&usqp=CAU" />
                            <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#030F2D' }} >Business Club</Typography>
                        </Box>
                        <Typography style={{ fontSize: '12px', fontWeight: 600, color: '#FFFFFF', background: '#2BAA61', borderRadius: '10px', padding: '2px 8px' }} >9+</Typography>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }} >
                            <Avatar src="https://as2.ftcdn.net/v2/jpg/02/46/15/83/1000_F_246158361_zWIIhiPDrT8Miex4Xmnzo4Jes4lABb61.jpg" />
                            <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#030F2D' }} >Finance and Electronics Manufacturers</Typography>
                        </Box>
                        <Typography style={{ fontSize: '12px', fontWeight: 600, color: '#FFFFFF', background: '#2BAA61', borderRadius: '10px', padding: '2px 8px' }} >3</Typography>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }} >
                            <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwB-N_Up4hk3OVya-m92ktZMn-H8a3eFUDIWG63E2vyqROcVHH4AiMophJsqN7qfICm7s&usqp=CAU" />
                            <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#030F2D' }} >Haylie Dorwart</Typography>
                        </Box>
                        <Typography style={{ fontSize: '12px', fontWeight: 600, color: '#FFFFFF', background: '#2BAA61', borderRadius: '10px', padding: '2px 8px' }} >5</Typography>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }} >
                            <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-ihS3fAvKqH5iJ5R3j5KR7VXd5aoYVlbk5cmTyy-y0vU90nCYkWvsRXdQfAy02AgoZ8k&usqp=CAU" />
                            <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#030F2D' }} >Lindsey Passaquindici Arcand</Typography>
                        </Box>
                        <Typography style={{ fontSize: '12px', fontWeight: 600, color: '#FFFFFF', background: '#2BAA61', borderRadius: '10px', padding: '2px 8px' }} >1</Typography>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }} >
                            <Avatar src="https://as2.ftcdn.net/v2/jpg/02/46/15/83/1000_F_246158361_zWIIhiPDrT8Miex4Xmnzo4Jes4lABb61.jpg" />
                            <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#030F2D' }} >Jaylon Dokidis</Typography>
                        </Box>
                        <Typography style={{ fontSize: '12px', fontWeight: 600, color: '#FFFFFF', background: '#2BAA61', borderRadius: '10px', padding: '2px 8px' }} >3</Typography>
                    </Box>
                </Box>
            </Paper>
            <AppHeader />
        </Box>
    );
}
