import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBO, imgBO1, imgPQ, imgPQ1, imgPSO, imgPSO1 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      loading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  whyCatoo = [
    {
      img: imgPQ,
      title: 'Post quotations',
      descreption: 'Our “Ask for Quote” feature helps you to post your product requirements and connect with suppliers worldwide.'
    },
    {
      img: imgBO,
      title: 'Business oppurtunity',
      descreption: 'Our users can post “BO” seeking for meaningful and strategic business collaborations.'
    },
    {
      img: imgPSO,
      title: 'Post sales offer',
      descreption: 'Showcase irresistible sales offers reaching a wider audience to attract potential buyers.'
    }
  ]

  whyCatooDetails = [
    {
      img: imgPQ1,
      title: 'Ask for Quote',
      subtitle: 'Post quotations',
      points: ["Users post detailed quotation requests on the platform.", "Information includes quantity, desired specifications, and delivery locations.", "Clear deadline set for suppliers to submit quotations.", "Suppliers respond with competitive bids within the timeframe."]
    },
    {
      img: imgBO1,
      title: 'Business opportunity',
      subtitle: 'Elevate your business',
      points: ["Users post lucrative business opportunities on our platform.", "Information includes business locations and details seeking collaborations.", "Transform opportunities into successful ventures and grow business.", "Foster meaningful connections worldwide by posting these opportunities."]
    },
    {
      img: imgPSO1,
      title: 'Post sales offers',
      subtitle: 'Sales Made Simple',
      points: ["Users can post detailed sales offers on the platform.", "Sellers can specify the minimum order quantity and attract potential buyers.", "Sellers provide information about the specific locations where the offers are available.", "Sellers define the price range for their products ensuring transparency for potential buyers."]
    }
  ]
  // Customizable Area End
}
