import { Box, styled } from "@material-ui/core";

// @ts-ignore
const ResponsiveBox = styled(Box)(() => ({
  minHeight: "80vh",
  maxwidth: "47vw",
  background: "#FFFEFE",
  borderRadius: "12px",
  marginRight: "14vw",
  width: '620px',
  boxShadow: "0px 4px 28px 0px rgba(131, 131, 131, 0.17)",
  '@media (max-width: 1024px)': {
    width: "60%",
    margin: 'auto'
  },
  '@media (max-width: 768px)': {
    width: "80%",
  },
  '@media (max-width: 425px)': {
    width: "100%",
    height: "100%",
    maxHeight: "unset"
  },
}))

export default ResponsiveBox;
