import { Field } from "formik";
import React, { ChangeEvent } from "react";

interface TextInputProps {
  onChange: (e: ChangeEvent) => void,
  name: string;
  isInvalid?: boolean;
  type?: string;
}

const TextInput = ({ isInvalid = false, type = 'text', ...rest }: TextInputProps) => {
  return (<Field style={{
    borderRadius: '12px',
    border: isInvalid ? '1px solid #FF583A' : '1px solid #F3EAFA',
    background: '#FFF',
    width: '100%',
    marginBottom: '1.2rem',
    maxHeight: '56px',
    padding: '1rem',
    fontFamily: 'HK Grotesk',
    fontStyle: 'normal',
  }} {...rest} type={type} />)
}

export default TextInput;
