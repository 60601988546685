import React from "react";
// Customizable Area Start
import SuggestionPageWebController, {
    Props
} from "./SuggestionPageController.web";

import { StyleRules, withStyles } from "@material-ui/core/styles";

import Loader from "../../../components/src/Loader.web";
import CloseIcon from '@material-ui/icons/Close';
import { Avatar, Box, Button, Paper, Typography } from "@material-ui/core";
import { imgVerify } from "./assets";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        backgroundColor: '#F6F6F6',
        flex: 1,
        overflowX: 'hidden'
    },
    headerBox: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '25px 70px', 
        backgroundColor: '#FFFFFF', 
        borderBottom: '0.5px solid #F3EAFA'
    },
    bodyBox: {
        display: 'flex', 
        flexDirection: 'column'
    },
    profileBox: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 24px 0px #CECECEBF',
        gap: '6px',
        margin: '10px',
        height: '229px',
        width: '199px',
        borderRadius: '12px', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    profileTxt: {
        fontSize: '12px',
        color: '#999BAC'
    },
    continueBtn: {
        color: '#5D248A',
        backgroundColor: '#FBFBFF',
        fontWeight: 700,
        fontSize: '16px',
        borderRadius: '12px',
        width: '250px',
        height: '56px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FBFBFF'
        }
    },
    skipBtn: {
        color: '#FBFBFF',
        backgroundColor: '#5D248A',
        fontWeight: 700,
        fontSize: '16px',
        borderRadius: '12px',
        width: '250px',
        height: '56px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#5D248A'
        }
    }
    // Customizable Area End
}

export class SuggestionPage extends SuggestionPageWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { loading, recommendationData, selectedFollowingId } = this.state;
        const { classes } = this.props;

        return (
            <Paper className={classes.root} >
                <Loader loading={loading} />
                <Box className={classes.headerBox} >
                    <Typography style={{ fontSize: '24px', fontWeight: 500 }}>
                        People you may know
                    </Typography>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/'} />
                </Box>
                <Box className={classes.bodyBox} >
                    <Box style={{ display: 'flex', flexWrap: 'wrap', padding: '25px 60px' }}>
                        {recommendationData.slice(0,18).map((item: any, index: number) => (
                            <Box key={index} className={classes.profileBox} >
                                <Avatar src={item.attributes.profile_picture} style={{ height: '80px', width: '80px' }} />
                                <Box style={{ display: 'flex' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 500 }} >{item.attributes.user_name}</Typography>
                                    {item.attributes.verified && <img src={imgVerify} alt="verified" />}
                                </Box>
                                <Typography className={classes.profileTxt} >{item.attributes.business_name} | {item.attributes.business_type}</Typography>
                                <Typography className={classes.profileTxt} >{item.attributes.designation} | {item.attributes.city}</Typography>
                                <Button style={{
                                    color: (selectedFollowingId.includes(item.id) || item.attributes.following) ? '#5D248A' : '#FBFBFF',
                                    backgroundColor: (selectedFollowingId.includes(item.id) || item.attributes.following) ? '#FBFBFF' : '#5D248A',
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    borderRadius: '12px',
                                    width: '135px',
                                    height: '32px',
                                    textTransform: 'none'
                                }}
                                    onClick={() => this.handleFollow(item)} >
                                    {(selectedFollowingId.includes(item.id) || item.attributes.following) ? 'Following' : 'Follow'}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                    <Box style={{ display: 'flex', gap: '6px', padding: '25px 70px', justifyContent: 'end' }}>
                        <Button className={classes.continueBtn} onClick={this.createFollower} >Continue</Button>
                        <Button className={classes.skipBtn} onClick={() => window.location.href = '/'}>Skip</Button>
                    </Box>
                </Box>
            </Paper>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(SuggestionPage);
// Customizable Area End