import { Button, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import * as Yup from 'yup';
import CustomStepper from "./CustomStepper";
import { Formik } from "formik";
import TextLabel from "./TextLabel";
import Select from 'react-select';
import InsideDiv from "./InsideDiv";
import ResponsiveBox from "./ResponsiveBox";

interface CategoriesProps {
  arrayCategories: Array<{ id: number, name: string }>,
  arraySubcategories: Array<{ id: number, name: string }>,
  handleChange: (data: {
    category: string,
    subcategory: string,
  }) => void,
  changePage: (page: number) => void,
  getCategories: () => void,
  getSubcategories: () => void,
}

const Categories = ({ handleChange, changePage, getCategories, arrayCategories, getSubcategories, arraySubcategories }: CategoriesProps) => {
  useEffect(() => {
    getCategories();
    getSubcategories();
  }, [])

  const customStyles = {
    valueContainer: (base: Record<string, string>) => ({
      ...base,
      borderColor: "#9e9e9e",
      height: "3.5rem"
    }),
    dropdownIndicator: (base: Record<string, string>) => ({
      ...base,
      color: "black",
      margin: "0.4vh"
    }),
    control: (styles: Record<string, string>) => ({
      ...styles,
      cursor: "pointer",
      fontFamily: "HK Grotesk",
      borderRadius: "12px",
      border: "1px solid #6A299F",
      background: "#FFF",
      paddingLeft: "0.5rem",
      marginBottom: '1rem',
    }),
    placeholder: (styles: Record<string, string>) => ({
      ...styles,
      fontFamily: "HK Grotesk"
    }),
    option: (
      styles: Record<string, string>,
      { isFocused }: { isFocused: boolean }
    ) => ({
      ...styles,
      fontFamily: "HK Grotesk",
      borderBottom: "1px solid #EBEBEE",
      background: "transparent",
      alignItems: "center",
      display: "flex",
      height: "3rem",
      color: isFocused
        ? "var(--primary-500, #6A299F)"
        : "var(--On-Background, #030F2D)",
      ":before": {
        boxShadow: isFocused
          ? "0px 0px 0px 1px var(--primary-500, #6A299F)"
          : "0px 0px 0px 1px var(--Subtext, #999BAC)",
        background: isFocused ? "#6A299F" : "transparent",
        border: "3px solid #FFF",
        content: '" "',
        borderRadius: "12px",
        height: "0.5rem",
        width: "0.5rem",
        marginRight: "0.5rem",
        marginLeft: "0.5rem"
      }
    }),
    menu: (styles: Record<string, string>) => ({
      ...styles,
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 4px 28px 0px rgba(131, 131, 131, 0.17)"
    }),
    menuList: (base: Record<string, string>) => ({
      ...base,
      maxHeight: "11rem",
      "::-webkit-scrollbar": {
        width: "0.5rem"
      },
      "::-webkit-scrollbar-track": {
        background: "var(--primary-50, #F3EAFA)",
        borderRadius: "12px",
        marginTop: "0.6rem",
        marginBottom: "3.5rem",
        width: "0.5rem",
        height: "6.5rem"
      },
      "::-webkit-scrollbar-thumb": {
        background: "var(--primary-400, #782EB3)",
        height: "2.5rem",
        width: "0.5rem",
        borderRadius: "12px"
      }
    }),
    indicatorSeparator: (base: Record<string, string>) => ({
      ...base,
      borderColor: "#E2E2E2",
      height: "2.5rem"
    })
  };


  const CategoriesFormSchema = Yup.object().shape({
    category: Yup.string().required("Please enter your category."),
    subcategory: Yup.string().required("Please enter your subcategory."),
  });

  const transformForSelect = (array: any) => {
    return array.map((e: any) => ({ value: e.name, label: e.name }))
  }

  return (
    <ResponsiveBox
      data-testid='Box'
      style={{ height: 'fit-content'}}
    >
      <InsideDiv className="country-select">
        <CustomStepper activeStep={4} maxSteps={6} />
        <Typography
          data-testid="TitleText"
          style={{
            fontFamily: "HK Grotesk",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "140%",
            marginTop: '2rem',
            marginBottom: '2.5rem'
          }}
        >
          Select your Business Category & Sub Category
        </Typography>
        <Formik data-testid='Formik' validateOnMount initialValues={{
          category: '',
          subcategory: '',
        }} validationSchema={CategoriesFormSchema} onSubmit={(data: {
          category: string;
          subcategory: string;
        }) => { handleChange(data); changePage(5) }}>
          {({
            setFieldValue,
            handleSubmit,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ width: '100%' }}>
                <TextLabel text="Category" />
                <Select
                  data-testid="Select1"
                  styles={customStyles}
                  className="basic-single"
                  classNamePrefix="select"
                  options={transformForSelect(arrayCategories)}
                  placeholder={'Select your category'}
                  onChange={(e) => setFieldValue("category", e?.value)}
                />
                <TextLabel text="Subcategory" />
                <Select
                  data-testid="Select2"
                  styles={customStyles}
                  className="basic-single"
                  classNamePrefix="select"
                  options={transformForSelect(arraySubcategories)}
                  placeholder={'Select your sub category'}
                  onChange={(e) => setFieldValue("subcategory", e?.value)}
                />
              </div>
              <Button
                data-testid="CategoryNextButton"
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  background: !isValid ? "#999BAC" : "#5D248A",
                  color: "var(--basic-white, #FFF)",
                  fontFamily: "HK Grotesk",
                  fontWeight: 700,
                  top: "3rem",
                  textTransform: "capitalize",
                }}
                disabled={!isValid}
                type="submit"
              >
                Next
              </Button>
            </form>
          )}
        </Formik>
      </InsideDiv>
    </ResponsiveBox>
  )
}

export default Categories;
