import React, { ChangeEvent } from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Paper, 
  InputAdornment,
  Input,
  IconButton,
  // Customizable Area End

} from "@material-ui/core";
const background = require('../assets/background.png');
// Customizable Area Start

import {  Check } from "@material-ui/icons";




// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  handlePasswordChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    this.txtInputPasswordProps.onChange(target.value,'password')
  }
  handleConfirmPasswordChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    this.txtInputPasswordProps.onChange(target.value,'confirmPassword')
  }
  // Customizable Area Start
  // Customizable Area End
 
  render() {
    const getIcons = (text: string) => {
      if (text == 'grey') {
        return
      } else if (text == 'green') {
        return (<Check fontSize="small" />)
      } else if (text == 'red') {
        return (<Check fontSize="small" />)
      }
    }
    return (
      // Customizable Area Start
      <div>
         <Paper
          data-testid='Background'
          style={{
            width: '100vw',
            height: '100vh',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
          style={{
            height: '80vh',
            width: '47vw',
            background: '#FFFEFE',
            borderRadius: '12px',
            boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
            marginRight: '14vw',
            maxHeight: '818px',
            maxWidth: '684px'
            // position:'rela'
          }}
          >
            <div
            className="country-select"
            style={{
              position: 'relative',
              height: '80vh',
              boxSizing: 'border-box',
              padding: '5rem 4rem',
            }}
            >
              <div
              // style={{border:'1px solid red'}}
              >

                <Typography
                  data-testid='CreateAccountDescription'
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'HK Grotesk',
                    fontSize: '36px',
                    lineHeight: '140%',
                    fontStyle: 'normal',
                    marginBottom: '15px',
                  }}
                >
                  Forgot Password ?
                </Typography>
                <Typography
                  data-testid='CreateAccountDescription'
                  style={{
                    color: 'var(--Subtext, #999BAC)',
                    fontFamily: 'HK Grotesk',
                    fontSize: '19px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '140%',
                    marginBottom: '64px',

                  }}
                >
                  Create new password
                </Typography>
              </div>
              <form
                style={{
                  height: '45vh',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  // border:'1px solid green',
                  marginTop: '-30px'
                }}
              >               
                <Typography variant="h6"
                  style={{
                    color: 'black',
                    fontFamily: 'HK Grotesk',
                    fontSize: '19px',
                    fontStyle: 'normal',
                    lineHeight: '140%',
                    marginBottom: '10px',
                  }}
                >
                  Password
                </Typography>
                <Input
                  data-test-id="txtInputPassword"
                  placeholder={'password'}
                  type={this.state.inputType}
                  // placeholder={"New Password"}
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  fullWidth={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                       
                      </IconButton>
                    </InputAdornment>
                  }
                />
                
                <div>
                  <Typography variant="h6"
                    style={{
                      color: 'black',
                      fontFamily: 'HK Grotesk',
                      fontSize: '19px',
                      fontStyle: 'normal',
                      lineHeight: '140%',
                      marginBottom: '10px',
                    }}
                  >
                    Confirm Password
                  </Typography>
                  <Input
                  data-test-id="txtInputConfirmPassword"
                  type={"password"}
                  placeholder={"Renter Your New Password"}
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  fullWidth={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                       
                      </IconButton>
                    </InputAdornment>
                  }
                />                
                </div>
                <div>
                  <Typography variant="h6"
                    style={{
                      color: 'black',
                      fontFamily: 'HK Grotesk',
                      fontSize: '19px',
                      fontStyle: 'normal',
                      lineHeight: '140%',
                      marginBottom: '10px',
                    }}
                  >
                    Password must:
                </Typography>
                  <p style={{ color: this.state.reg1, fontSize: "14px", margin: '3px',display: 'flex', padding: '2px' }}>{getIcons(this.state.reg1)}be atleast 8 characters long</p>
                  <p style={{ color: this.state.reg2, fontSize: "14px", margin: '3px',display: 'flex', padding: '2px' }}>{getIcons(this.state.reg2)}include atleast 1 uppercase letter(A-Z)</p>
                  <p style={{ color: this.state.reg3, fontSize: "14px", margin: '3px',display: 'flex', padding: '2px' }}>{getIcons(this.state.reg3)}include atleast 1 lowercase letter(a-z)</p>
                  <p style={{ color: this.state.reg4, fontSize: "14px", margin: '3px',display: 'flex', padding: '2px' }}>{getIcons(this.state.reg4)}include atleast 1 special character(@,#,$ etc)</p>
                </div>
                <Button
                  data-testid='NextButton'
                  style={{
                    display: 'flex',
                    width: '100%',
                    padding: '1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: this.state.disable ? '#999BAC' : '#5D248A',
                    color: 'var(--basic-white, #FFF)',
                    fontFamily: 'HK Grotesk',
                    borderRadius: '12px',
                    fontWeight: 700,
                    textTransform: 'capitalize',
                    marginTop: '2rem',
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                  }}
                  disabled={this.state.disable}
                  onClick={() => this.newCreatePassword()}
                >
                  Create
                </Button>
                <Typography
                  style={{
                    color: 'var(--Subtext, #999BAC)',
                    fontFamily: 'HK Grotesk',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '140%',
                    marginTop: '2rem',
                    textAlign: 'center',
                  }}
                >
                  Already have an account? Signin here
                </Typography>
              </form>
            </div>
          </Box>
        </Paper>
      </div>
      
  // Customizable Area End
);
  }
}

// Customizable Area Start


// Customizable Area End

