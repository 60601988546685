import React from "react";
import { View } from "react-native";
import Select from "react-select";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start

import { Paper, Typography, Button } from "@material-ui/core";
import ResponsiveBox from "../../../components/src/ResponsiveBox";
import InsideDiv from "../../../components/src/InsideDiv";

const background = require("../assets/background.png");

// Customizable Area End
const configJSON = require("./config");

interface Props {
  // Customizable Area Start
  navigation: any;
  style: any;
  id: string;
  disable: boolean;
  allowPropChange: boolean;
  value: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dataSource: any[];
  countryCodeSelected: string;
  mobileNo: string;
  token: string;
  placeHolder: string;
  disable: boolean;
  label: any;
  hoveredLabel: string;
  // Customizable Area End
}

interface SS {}

export default class CountryCodeSelector extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  countryCodeApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      dataSource: [],
      countryCodeSelected: "",
      mobileNo: "",
      token: "",
      placeHolder: configJSON.countryPlaceHolder,
      disable: this.props.disable,
      label: null,
      hoveredLabel: ""
    };
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const countryNames = this.state.dataSource.map(({ label, value }) => ({
      label: label.slice(6, label.indexOf("(")).trim(),
      value
    }));

    const customStyles = {
      menuList: (style: Record<string, string>) => ({
        ...style,
        "::-webkit-scrollbar-track": {
          width: "0.5rem",
          height: "6.5rem",
          background: "var(--primary-50, #F3EAFA)",
          borderRadius: "12px",
          marginTop: "0.6rem",
          marginBottom: "3.5rem",
        },
        "::-webkit-scrollbar-thumb": {
          width: "0.5rem",
          borderRadius: "12px",
          background: "var(--primary-400, #782EB3)",
          height: "2.5rem",
        },
        maxHeight: "11rem",
        "::-webkit-scrollbar": {
          width: "0.5rem"
        }
      }),
      container: (base: Record<string, string>) => ({
        ...base,
        fontFamily: "HK Grotesk",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
      }),
      dropdownIndicator: (style: Record<string, string>) => ({
        ...style,
        color: "black",
        margin: "0.4vh"
      }),
      control: (base: Record<string, string>) => ({
        ...base,
        cursor: "pointer",
        fontFamily: "HK Grotesk",
        borderRadius: "12px",
        border: "1px solid #6A299F",
        background: "#FFF",
        paddingLeft: "0.5rem"
      }),
      placeholder: (styles: Record<string, string>) => ({
        ...styles,
        fontFamily: "HK Grotesk",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#232227'
      }),
      indicatorSeparator: (style: Record<string, string>) => ({
        ...style,
        height: "2.5rem",
        borderColor: "#E2E2E2",
      }),
      option: (
        styles: Record<string, string>,
        { isFocused }: { isFocused: boolean }
      ) => ({
        ...styles,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '140%',
        alignItems: "center",
        height: "3rem",
        color: isFocused
          ? "var(--primary-500, #6A299F)"
          : "var(--On-Background, #030F2D)",
        fontFamily: "HK Grotesk",
        display: "flex",
        borderBottom: "1px solid #EBEBEE",
        background: "transparent",
        ":before": {
          boxShadow: isFocused
            ? "0px 0px 0px 1px var(--primary-500, #6A299F)"
            : "0px 0px 0px 1px var(--Subtext, #999BAC)",
          height: "0.5rem",
          width: "0.5rem",
          border: "3px solid #FFF",
          content: '" "',
          borderRadius: "50%",
          marginRight: "0.5rem",
          marginLeft: "0.5rem",
          background: isFocused ? "rgba(106, 41, 159, 0.7)" : "transparent",
        }
      }),
      valueContainer: (style: Record<string, string>) => ({
        ...style,
        height: "3.5rem",
        borderColor: "#9e9e9e",
      }),
      menu: (base: Record<string, string>) => ({
        ...base,
        background: "#FFF",
        boxShadow: "0px 4px 28px 0px rgba(131, 131, 131, 0.17)",
        borderRadius: "12px"
      })
    };

    return (
      <View data-testid="Container" style={{ zIndex: 99 }}>
        <Paper
          data-testid="Background"
          style={{
            width: "100vw",
            height: "100vh",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <ResponsiveBox>
            <InsideDiv className="country-select">
              <Typography
                data-testid="SelectCountryTitle"
                style={{
                  fontFamily: "HK Grotesk",
                  fontSize: "40px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "140%",
                  color: 'var(--On-Background, #030F2D)'
                }}
              >
                Select Country
              </Typography>
              <Typography
                data-testid="SelectCountryDescription"
                style={{
                  color: "var(--Subtext, #999BAC)",
                  fontFamily: "HK Grotesk",
                  fontSize: "19px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "140%",
                  marginTop: "8px",
                  marginBottom: "64px",
                }}
              >
                Before creating your profile, kindly choose the country you
                belong to
              </Typography>
              <form
                style={{
                  height: "45vh"
                }}
              >
                <Select
                  data-testid="Select"
                  styles={customStyles}
                  className="basic-single"
                  classNamePrefix="select"
                  options={countryNames}
                  placeholder={this.state.placeHolder}
                  onChange={this.handleChange}
                  isDisabled={this.state.disable}
                />
                <Button
                  data-testid="NextButton"
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12px",
                    background: this.state.label ? "#5D248A" : "#999BAC",
                    color: "var(--basic-white, #FFF)",
                    fontFamily: "HK Grotesk",
                    fontWeight: 700,
                    top: "14rem",
                    textTransform: "capitalize"
                  }}
                  disabled={!this.state.label}
                  onClick={() =>
                    this.props.navigation.navigate("EmailAccountRegistration")
                  }
                >
                  Next
                </Button>
              </form>
            </InsideDiv>
          </ResponsiveBox>
        </Paper>
      </View>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange(item: any) {
    this.setState({ label: item.label });
    const msg = new Message(getName(MessageEnum.CountryCodeMessage));
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), item.value);
    runEngine.sendMessage(getName(MessageEnum.CountryCodeMessage), msg);
  }

  async componentDidMount() {
    this.makeRemoteRequest();
  }

  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: ` ${item.attributes.emoji_flag} ${item.attributes.name} (${
        item.id
      }) +${item.attributes.country_code}`,
      value: item.attributes.country_code
    }));
  };

  async receive(from: String, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.countryCodeApiCallId != null &&
      this.countryCodeApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      //
      if (responseJson && !responseJson.errors) {
        this.setState({
          dataSource: this.countryCodesToDropDown(responseJson.data)
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCountryCodes
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
