import { MenuItem, OutlinedInput, TextField, styled } from "@material-ui/core";

const StyledInput = styled(OutlinedInput)({
  height: '56px', 
  padding: '10px 16px', 
  borderRadius: '12px', 
  gap: '8px', 
  fontSize: '16px', 
  border: '1px solid #F3EAFA', 
  color: '#030F2D',
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: 'transparent'
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: 'transparent'
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: '1px solid #6A299F',
  },
});

const StyledTextField = styled(TextField)({
  height: '56px', 
  borderRadius: '12px', 
  gap: '8px', 
  fontSize: '16px', 
  border: '1px solid #F3EAFA', 
  color: '#030F2D',
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: 'transparent'
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: 'transparent'
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: '1px solid #6A299F',
  },
});

const StyledMenuItem = styled(MenuItem)({
  "&:hover": {
    color: '#6A299F'
  },
});

export { StyledInput, StyledTextField, StyledMenuItem };
