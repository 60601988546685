import React from "react";
// Customizable Area Start
import {
  Box,
  FormControl,
  FormHelperText,
  Paper,
  Select,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createTheme, styled } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import LandingPageHeader from "../../../components/src/LandingPageHeader.web";
import { imgTop } from "../../landingpage/src/assets";
import { imgSuccess } from "../../contactus/src/assets";
import './Contactus.css';
import { StyledInput, StyledMenuItem, StyledTextField } from '../../../components/src/StyledInput.web'
// Customizable Area End

import ContactusController, { Props } from "./ContactusController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    const StyledSelect = styled(Select)({
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent'
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent'
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent'
      },
    });

    const { loading, firstName, lastName, query, contactNumber, emailAddress, description, contactSuccessftul, countryCode, selectedCountryCode } = this.state;

    return (
      <Paper style={{ boxShadow: 'none', borderRadius: '0px', overflow: 'auto' }} >
        <Loader loading={loading} />
        <Box style={{ backgroundImage: `url(${imgTop})`, height: '100vh', backgroundSize: 'cover', display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
          <LandingPageHeader />
          <Box style={{ display: 'flex', maxWidth: '90vw', flexDirection: 'column', gap: '5vh' }}>
            <Box>
              <p className='heading'>You’ve got questions?</p>
              <p className='heading'>We’ve got answers!</p>
            </Box>
            <Typography style={{ fontSize: '24px', textAlign: 'center', color: '#FFFFFF' }}>Everything you need to know about the product. Please feel free to get in touch with us in case you can’t find the answer you’re looking for.</Typography>
            {contactSuccessftul ?
              <Box className='successMiddleBox'>
                <img src={imgSuccess} style={{ maxWidth: '750px', width: '100%' }} />
                <Typography style={{ fontSize: '20px', fontWeight: 500, color: '#030F2D', marginTop: '35px' }}>Thank you for contacting us.</Typography>
                <Typography style={{ fontSize: '20px', fontWeight: 500, color: '#030F2D' }}>Our team will get back to you regarding your query shortly.</Typography>
              </Box> :
              <Box className='middleBox'>
                <Box>
                  <Typography style={{ fontSize: '32px', fontWeight: 700, color: '#030F2D' }}>Contact us</Typography>
                  <Typography style={{ fontSize: '13px', color: '#999BAC' }}>Contact our friendly team to resolve your queries.</Typography>
                </Box>
                <Box style={{ display: 'flex', gap: '35px', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <Box style={{ display: 'flex', flexDirection: 'column', gap: '44px', width: '100%', maxWidth: '520px' }} >
                    <FormControl variant="outlined" style={{ gap: '12px', padding: '10px' }} >
                      <FormHelperText style={{ fontSize: '18px', fontWeight: 500, color: '#030F2D', marginLeft: '0px' }} >Your first name</FormHelperText>
                      <StyledInput
                        placeholder="First Name"
                        value={firstName}
                        onChange={this.handleFirstNameChange}
                      />
                    </FormControl>
                    <FormControl variant="outlined" style={{ gap: '12px', padding: '10px' }} >
                      <FormHelperText style={{ fontSize: '18px', fontWeight: 500, color: '#030F2D', marginLeft: '0px' }} >Your last name</FormHelperText>
                      <StyledInput
                        placeholder="Last Name"
                        value={lastName}
                        onChange={this.handleLastNameChange}
                      />
                    </FormControl>
                    <FormControl variant="outlined" style={{ gap: '12px', padding: '10px' }} >
                      <FormHelperText style={{ fontSize: '18px', fontWeight: 500, color: '#030F2D', marginLeft: '0px' }} >Choose your query</FormHelperText>
                      <Autocomplete
                        options={this.topQuery}
                        getOptionLabel={(option) => option}
                        value={query}
                        onChange={this.handleQueryChange}
                        renderInput={(params) =>
                          <StyledTextField {...params}
                            placeholder="Query Box"
                            variant="outlined"
                          />}
                      />
                    </FormControl>
                  </Box>
                  <Box style={{ display: 'flex', flexDirection: 'column', gap: '44px', width: '100%', maxWidth: '520px' }} >
                    <FormControl variant="outlined" style={{ gap: '12px', padding: '10px' }} >
                      <FormHelperText style={{ fontSize: '18px', fontWeight: 500, color: '#030F2D', marginLeft: '0px' }} >Your contact number</FormHelperText>
                      <StyledInput
                        placeholder="Contact Number"
                        value={contactNumber}
                        onChange={this.handleContactNumberChange}
                        startAdornment={
                          <StyledSelect
                            value={selectedCountryCode}
                            onChange={this.handleCountryCodeChange}
                          >
                            {countryCode.map((item: any) => (
                              <StyledMenuItem value={item.attributes?.country_code.toString()} >(+{item.attributes?.country_code.toString()})</StyledMenuItem>
                            ))}
                          </StyledSelect>
                        }
                      />
                    </FormControl>
                    <FormControl variant="outlined" style={{ gap: '12px', padding: '10px' }} >
                      <FormHelperText style={{ fontSize: '18px', fontWeight: 500, color: '#030F2D', marginLeft: '0px' }} >Your email address</FormHelperText>
                      <StyledInput
                        placeholder="Email Address"
                        value={emailAddress}
                        onChange={this.handleEmailChange}
                      />
                    </FormControl>
                    <FormControl variant="outlined" style={{ gap: '12px', padding: '10px' }} >
                      <FormHelperText style={{ fontSize: '18px', fontWeight: 500, color: '#030F2D', marginLeft: '0px' }} >Describe your query</FormHelperText>
                      <TextareaAutosize
                        maxRows={4}
                        style={{ height: '112px', padding: '10px 32px', borderRadius: '12px', gap: '8px', fontSize: '16px', border: '1px solid #F3EAFA', color: '#030F2D' }}
                        placeholder="Description..."
                        value={description}
                        onChange={this.handleDescriptionChange}
                        onFocus={this.handleFocusTextArea}
                        onBlur={this.handleBlurTextArea}
                      />
                    </FormControl>
                    <button className='submitBtn' onClick={this.createQuery} >Submit</button>
                  </Box>
                </Box>
              </Box>}
          </Box>
        </Box>
      </Paper>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
