import { Radio, Typography, styled } from "@material-ui/core";
import React, { ChangeEvent } from "react";

interface CustomRadioButtonProps {
  headText: string,
  descriptionText: string,
  picked: string,
  onChange: (e: ChangeEvent) => void,
}

const CustomRadioButton = ({ headText, descriptionText, picked, onChange }: CustomRadioButtonProps) => {
  const StyledRadio = styled(Radio)(() => ({
    "&.Mui-checked": {
      color: "var(--primary-500, #6A299F)"
    }
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '1rem', paddingLeft: '0px' }}>
        <StyledRadio data-testid='RadioButton' value={headText} checked={headText === picked} name='businessType' onChange={onChange} />
      </div>
      <div>
        <Typography
          data-testid='RadioHeadText'
          style={{
            color: headText === picked ? 'var(--primary-500, #6A299F)' : 'var(--On-Background, #030F2D)',
            fontFamily: 'HK Grotesk',
            fontSize: '1.3rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '140%',
            marginBottom: '6px'
          }}
        >
          {headText}
        </Typography>
        <Typography
          data-testid='RadioDescriptionText'
          style={{
            color: 'var(--Subtext, #999BAC)',
            fontFamily: 'HK Grotesk',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '140%',
          }}
        >
          {descriptionText}
        </Typography>
      </div>
    </div>
  )
}

export default CustomRadioButton;
