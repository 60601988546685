import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: 'HK Grotesk, sans-serif',
  },
});

const CustomThemeProvider = (props: any) => {
    const { children } = props
  return (
    <ThemeProvider theme={theme}>
     {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
