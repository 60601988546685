import React from "react";
// Customizable Area Start
import FAQsPageWebController, {
  Props
} from "./FAQsPageController.web";

import { StyleRules, withStyles } from "@material-ui/core/styles";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, OutlinedInput, Paper, Typography } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Loader from "../../../components/src/Loader.web";
import LandingPageHeader from "../../../components/src/LandingPageHeader.web";
import { imgSearch, imgTop } from "./assets";
// Customizable Area End

const styles: StyleRules = {
  // Customizable Area Start
  root: {
    boxShadow: 'none',
    borderRadius: '0px',
  },
  topBox: {
    backgroundImage: `url(${imgTop})`,
    height: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  topContainor: {
    display: 'flex',
    maxWidth: '90vw',
    flexDirection: 'column',
    gap: '5vh'
  },
  heading: {
    fontSize: '64px',
    fontWeight: 800,
    textAlign: 'center',
    color: '#FFFFFF',
    '@media (max-width: 600px)': {
      fontSize: '34px'
    }
  },
  middleBox: {
    display: 'flex',
    flexDirection: 'column',
    background: "#FFFFFF",
    borderRadius: "12px",
    margin: "14vh 0",
    boxShadow: "0px 4px 24px 0px #C0C0C040",
    padding: '50px',
    gap: '32px'
  },
  contactBox: {
    background: "#FFFFFF",
    borderRadius: "12px",
    boxShadow: "0px 4px 24px 0px #C0C0C040",
    padding: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  contactBtn: {
    height: '56px',
    backgroundColor: '#5D248A',
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
    borderRadius: '12px',
    padding: '10px 16px',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'none',
    width: '176px',
    '&:hover': {
      backgroundColor: '#5D248A'
    }
  },
  searchInput: {
    height: '46px',
    maxWidth: '388px',
    padding: '10px 16px',
    borderRadius: '12px',
    gap: '8px',
    border: '1px solid #F3EAFA', 
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'transparent'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: 'transparent'
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #6A299F',
    },
  }
  // Customizable Area End
}

export class FAQsPageWeb extends FAQsPageWebController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { loading, expanded, faqData, searchKey } = this.state;

    return (
      <Paper className={classes.root} >
        <Loader loading={loading} />
        <Box className={classes.topBox} >
          <LandingPageHeader />
          <Box className={classes.topContainor}>
            <Box>
            <Typography className={classes.heading}>You’ve got questions?</Typography>
            <Typography className={classes.heading}>We’ve got answers!</Typography>
            </Box>
            <Typography style={{ fontSize: '24px', textAlign: 'center', color: '#FFFFFF' }}>Everything you need to know about the product. Please feel free to get in touch with us in case you can’t find the answer you’re looking for.</Typography>
            <Box className={classes.middleBox}>
              <Box>
                <Typography style={{ fontSize: '32px', fontWeight: 700, color: '#030F2D' }}>Frequently asked questions</Typography>
                <Typography style={{ fontSize: '16px', fontWeight: 500, color: '#999BAC' }}>Have questions? We’re here to help.</Typography>
              </Box>
              <FormControl variant="outlined">
                <OutlinedInput
                  placeholder="Search"
                  value={searchKey}
                  onChange={this.searchChange}
                  className={classes.searchInput}
                  startAdornment={<img src={imgSearch} alt="search" />}
                />
              </FormControl>
              <Box>
                {
                  faqData.length > 0 ?
                  faqData.filter((data: any) => data.attributes.question.toLowerCase().toString().match(searchKey.toLowerCase().toString())).map((item: any, index: number) => (
                    <>
                      <Accordion expanded={expanded === index} onChange={this.handleChange(index)} style={{ boxShadow: 'none' }} key={index + 1}>
                        <AccordionSummary
                          expandIcon={expanded === index ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography style={{ fontSize: '18px', fontWeight: 700, color: '#030F2D' }} > {item.attributes.question} </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={{ fontSize: '16px', color: '#030F2D' }} > {item.attributes.answer} </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </>
                  )) : 
                  <Typography style={{ fontSize: '18px', fontWeight: 700, color: '#030F2D', textAlign: 'center' }} > Please Contact Us </Typography>
                }
              </Box>
              <Box className={classes.contactBox}>
                <Box>
                  <Typography style={{ fontSize: '19px', fontWeight: 500, color: '#030F2D' }}>Still have questions?</Typography>
                  <Typography style={{ fontSize: '13px', color: '#999BAC' }}>Can’t find the answer you are looking for? Please connect with our friendly team.</Typography>
                </Box>
                <Button className={classes.contactBtn} data-testid='contactBtn' onClick={() => window.location.href = "/ContactUS"} >Contact us</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(FAQsPageWeb);
// Customizable Area End