import { Box, Button, Input, MenuItem, Select, Typography, styled } from "@material-ui/core";
import React from "react";
import * as Yup from 'yup';
import CustomStepper from "./CustomStepper";
import { Field, Formik } from "formik";
import TextLabel from "./TextLabel";
import TextInput from "./TextInput";
import ErrorText from "./ErrorText";
import ResponsiveBox from "./ResponsiveBox";
import InsideDiv from "./InsideDiv";

interface ContactInformationProps {
  handleChange: (data: {
    contactName: string,
    numberCode: string,
    contactNumber: string,
    designation: string,
    websiteLink: string,
  }) => void,
  changePage: (page: number) => void,
}

const ContactInformation = ({ handleChange, changePage }: ContactInformationProps) => {
  const StyledSelect = styled(Select)(() => ({
    '&.MuiSelect-root': {
      boxShadow: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  }));


  const ContactInformationFormSchema = Yup.object().shape({
    contactName: Yup.string()
      .min(2, "Too Short!")
      .required("Please enter your contract name."),
    contactNumber: Yup.string()
      .min(4, "Too Short!")
      .required("Please enter your contact number."),
    designation: Yup.string()
      .min(2, "Too Short!")
      .required("Please enter your designation.")
  });

  return (
    <ResponsiveBox
      data-testid='Box'
      style={{ height: 'fit-content'}}
    >
      <InsideDiv className="country-select">
        <CustomStepper activeStep={3} maxSteps={6} />
        <Typography
          data-testid="TitleText"
          style={{
            fontFamily: "HK Grotesk",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "140%",
            marginTop: '2rem',
            marginBottom: '2.5rem'
          }}
        >
          Contact Information
        </Typography>
        <Formik data-testid='Formik' validateOnMount initialValues={{
          contactName: '',
          numberCode: '',
          contactNumber: '',
          designation: '',
          websiteLink: '',
        }} validationSchema={ContactInformationFormSchema} onSubmit={(data: {
          contactName: string;
          numberCode: string;
          contactNumber: string;
          designation: string;
          websiteLink: string;
        }) => { handleChange(data); changePage(4) }}>
          {({
            handleChange: handleFormChange,
            handleSubmit,
            errors,
            isValid,
            touched
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ width: '100%' }}>
                <TextLabel text="Contact name" />
                <TextInput isInvalid={!!(touched.contactName && errors.contactName)} name="contactName" onChange={handleFormChange} />
                {touched.contactName && errors.contactName && (
                  <ErrorText text={errors.contactName} />
                )}
              </div>
              <div style={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
              }}>
                <TextLabel text="Contact number" />
                <Box
                  style={touched.contactNumber && errors.contactNumber ? {
                    display: 'flex',
                    border: "1px solid red",
                    borderRadius: "8px",
                  } :
                    {
                      display: 'flex',
                      border: "1px solid lightgray",
                      borderRadius: "8px",
                    }}
                >
                  <StyledSelect data-testid="combobox" name="numberCode" onChange={handleFormChange} defaultValue={"+91"} disableUnderline label={"Code"} style={{
                    border: "none",
                    paddingLeft: "1rem",
                    color: "var(--Subtext, #999BAC)",
                    fontFamily: "HK Grotesk",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "22px"
                  }}>
                    <MenuItem value={"+91"}>+91</MenuItem>
                    <MenuItem value={"+55"}>+55</MenuItem>
                    <MenuItem value={"+80"}>+80</MenuItem>
                  </StyledSelect>
                  <Field name={"contactNumber"}>
                    {() => (
                      <Input
                        inputProps={{ "data-testid": "contactNumberInput" }}
                        onChange={handleFormChange}
                        name="contactNumber"
                        style={{
                          width: '100%',
                          padding: "7px 7px 7px 10px",
                          color: "#273567",
                          fontFamily: "HK Grotesk",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "22px",
                        }}
                        disableUnderline
                        type={"number"}
                      />
                    )}
                  </Field>
                </Box>
                {touched.contactNumber && errors.contactNumber && (
                  <ErrorText text={errors.contactNumber} />
                )}
              </div>
              <div style={{ width: '100%' }}>
                <TextLabel text="Designation" />
                <TextInput isInvalid={!!(touched.designation && errors.designation)} name="designation" onChange={handleFormChange} />
                {touched.designation && errors.designation && (
                  <ErrorText text={errors.designation} />
                )}
              </div>
              <div style={{ width: '100%' }}>
                <TextLabel text="Website link (if any)" />
                <TextInput name="websiteLink" onChange={handleFormChange} />
              </div>
              <Button
                data-testid="ContactInfoNextButton"
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  background: !isValid ? "#999BAC" : "#5D248A",
                  color: "var(--basic-white, #FFF)",
                  fontFamily: "HK Grotesk",
                  fontWeight: 700,
                  top: "3rem",
                  textTransform: "capitalize",
                }}
                disabled={!isValid}
                type="submit"
              >
                Next
              </Button>
            </form>
          )}
        </Formik>
      </InsideDiv>
    </ResponsiveBox>
  )
}

export default ContactInformation;
