import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import * as Yup from 'yup';
import CustomStepper from "./CustomStepper";
import { Formik } from "formik";
import Product from "./Product";
import InsideDiv from "./InsideDiv";

interface ProductsProps {
  handleChange: (data: {
    products: Array<{ type: string, text: string }>,
  }) => void,
  changePage: (page: number) => void,
  arrayProducts: Array<{ id: number, type: string, name: string }>,
  getProducts: () => void,
}

const useStyles = makeStyles((theme) => ({
  customBox: {
    height: 'auto',
    width: 'auto',
    background: '#FFFEFE',
    borderRadius: '12px',
    marginRight: '14vw',
    boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    maxHeight: '835px',
    maxWidth: '1260px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '.5rem',
    },
    '&::-webkit-scrollbar-track': {
      background: 'var(--primary-50, #F3EAFA)',
      borderRadius: '15px',
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
      width: '.5rem',
      height: '6.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--primary-400, #782EB3)',
      height: '4rem',
      width: '.5rem',
      borderRadius: '15px',
    },
    '@media (max-width: 425px)': {
      width: "100%",
      height: "100%",
      maxHeight: "unset"
    },
    '@media (max-width: 1024px)': {
      width: "60%",
      margin: 'auto'
    },
    '@media (max-width: 768px)': {
      width: "80%",
    },
  },
}));

const Products = ({ handleChange, changePage, getProducts, arrayProducts }: ProductsProps) => {
  useEffect(() => {
    getProducts();
  }, [])
  
  const classes = useStyles();

  const ProductsFormSchema = Yup.object().shape({
    products: Yup.array().required()
  });

  const transform = (products: Array<{ id: number, type: string, name: string }>) => {
    const types = products.map((e) => e.type)
    const uniqueTypes = [...new Set(types)];

    return uniqueTypes.map((type) => ({
      name: type,
      products: products.filter((e) => e.type === type).map((e) => e.name)
    }))
  }

  return (
    <Box
      data-testid='Box'
      className={classes.customBox}
    >
      <InsideDiv className="country-select">
        <CustomStepper activeStep={5} maxSteps={6} />
        <Typography
          data-testid="TitleText"
          style={{
            fontFamily: "HK Grotesk",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "140%",
            marginTop: '2rem',
          }}
        >
          Select your Products
        </Typography>
        <Typography
          data-testid="DescriptionText"
          style={{
            color: "var(--Subtext, #999BAC)",
            fontFamily: "HK Grotesk",
            fontSize: "19px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "140%",
            marginTop: "2px",
            marginBottom: "46px"
          }}
        >
          to find buyers from your category
        </Typography>
        <Formik data-testid='Formik' validateOnMount initialValues={{
          products: [],
        }} validationSchema={ProductsFormSchema} onSubmit={(data: {
          products: Array<{ type: string, text: string }>;
        }) => { handleChange(data); changePage(6) }}>
          {({
            values,
            setFieldValue,
            handleSubmit,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              {transform(arrayProducts).map((product) => {
                const mapped = product.products.map((e) => <Product setFieldValue={setFieldValue} type={product.name} text={e} products={values.products}/>)
                return (
                  <div>
                    <Typography
                      style={{
                        color: "var(--On-Background, #030F2D)",
                        fontFamily: "HK Grotesk",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        marginTop: "2.5rem",
                        marginBottom: "1.5rem"
                      }}
                    >
                      {product.name}
                    </Typography>
                    {mapped}
                  </div>
                )
              })}
              <Button
                data-testid="ProductsNextButton"
                style={{
                  display: "flex",
                  width: "524px",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  background: !isValid ? "#999BAC" : "#5D248A",
                  color: "var(--basic-white, #FFF)",
                  fontFamily: "HK Grotesk",
                  fontWeight: 700,
                  top: "3rem",
                  textTransform: "capitalize",
                }}
                disabled={!isValid}
                type="submit"
              >
                Next
              </Button>
            </form>
          )}
        </Formik>
      </InsideDiv>
    </Box>
  )
}

export default Products;
