import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

// Customizable Area Start
export type NavObject = TypeNav
export interface TypeNav {
  addListener: Function
  goBack: Function,
  getParam:Function,
  navigate:Function
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation:NavObject ;
    id: string;
    // Customizable Area Start

// Customizable Area End
}

interface S {
    email :string;
    password: string;
    confirmPassword:string;
    enablePasswordField: boolean;
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    fullName: string;
    reg1: string;
    reg2: string;
    reg3: string;
    reg4: string;
    reg5: string;
    loginemail: string;
    name: string;
    passwordValid: boolean;
    template_dis: string;
    disable: boolean;
    phoneNumber: string;
    inputType: string;
    loginpassword: string;
    showPassword: boolean;
    errors: {
        [key: string]: string;
    };
    registrationSuccess: boolean;
    isModalOpen: boolean,
    isEditBtn: boolean,
    getUserData: [],
    getShowUserData: {attributes: {id:number,name:string,description:string,status:string,};
},
    getUserDataArchive: [],
    isArchiveModalOpen: boolean
    getorganizationsDetails: [],

//create Quote
itemName: string,
itemPrice: string,
itemQuantity: string,
discount: string,
description: string,
//PUT for Achive
serialIdforArchive: string,
//Level 1
leveOneModal: boolean;
selectedRowData: {},
statusForlevelOne: string;
selectedStatus: string;
userRole: string;
userRolelevelOne: string;
anchorEl: null,
selectedOption: string,
isEditModalOpen: boolean,
isShowModalOpen: boolean,
isStatusModalOpen: boolean,
editClickDataLeveZero: [],
itemNameEdit: string,
itemPriceEdit: string,
itemQuantityEdit: string,
discountEdit: string,
descriptionEdit: string,
serialIdEdit: number;
openDialog: boolean;
// Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface ApiData {
    header?: Record<string, string>;
    contentType?: string;
    method: string;
    endPoint: string;
    body?: LoginPayload | TemplateData | Payload | TemplateIdData// Adjust the type of body as needed
    type?: string;
  }

  interface LoginPayload {
    data: {
      email: string;
      password: string;
    };
  }

  interface TemplateData {
    template: {
      name: string;
      description: string;
    };
  }

  interface Payload {
    template: {
      name: string;
      description: string;
      kpis_attributes: {
        [key: number]: {
          name: string;
          description: string;
          point: number;
        };
      };
    };
  }

  interface ChangeEvent {
    target: {
      name: string;
      value: string;
    };
  }


  interface TemplateIdData { 
      status: string; 
      comment: string;
      }

  interface ChangeEvent {
    target: {
      name: string;
      value: string;
    };
  }

  export interface EventList {
    id:number,
    attributes:{
        name: string,
        description: string,
        status: string

}
  }


export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
>
{

// Customizable Area Start
AttendancDetailsApi: string = "";
AttendancePostApi: string = "";
AttendancDetailsArchivedApi: string = "";
templatesApi: string = "";
ArchivedDataPutApi: string = "";
UpdateApiPutApi: string = "";
StatusDataPutApi: string = "";
loginPostDeatilsApi: string = "";
editUpdateLevelZeroApi: string = "";
//Level 1
getListApi: string = "";
getShowListApi: string = "";
levelOneTestingApi: string = "";

// Customizable Area End

constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
    ];

    
    this.state = {
        email:"",
        password:"",
        confirmPassword:"",
        enablePasswordField: true,
        txtInputValue: "",
        txtSavedValue: "A",
        enableField: false,
        // Customizable Area Start
        fullName: "",
        loginemail: "",
        name:"",
        template_dis:"",
        disable: true,
        phoneNumber: "",
        loginpassword: "",
        inputType: 'password',
        showPassword: false,
        reg1: 'gray',
        reg2: 'gray',
        reg3: 'gray',
        reg4: 'gray',
        reg5: 'gray',
        errors: {},
        registrationSuccess: false,
        isModalOpen: false,
        isEditBtn: false,
        getUserData: [],
        getShowUserData:{attributes:{
            id:0,
            name:'',
            description:'',
            status:'',
        }} ,
        getUserDataArchive: [],
        isArchiveModalOpen: false,
        passwordValid: false,
        getorganizationsDetails: [],
        itemName: '',
        itemPrice: '',
        itemQuantity: '',
        discount: '',
        description: '',
        //Put for Archive data
        serialIdforArchive: '',
        //Level 1
        leveOneModal: false,
        selectedRowData: '',
        statusForlevelOne: '',
        selectedStatus: 'under_review',
        userRole: '',
        userRolelevelOne: '',
        anchorEl: null,
        selectedOption: "",
        isEditModalOpen: false,
        isStatusModalOpen:false,
        isShowModalOpen: false,
        editClickDataLeveZero: [],
        itemNameEdit: '',
        itemPriceEdit: '',
        itemQuantityEdit: '',

        discountEdit: '',
        descriptionEdit: '',
        serialIdEdit: 0,
        openDialog: false,
        // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
}



// Customizable Area Start


createQuote = () => {
    this.handleClickOpenDialog();
    this.templatePostDeatils();
    this.getTemplateList();

}

quoteCancel = () => {
    this.setState({isStatusModalOpen:false})
    this.closeModal()

}

checkEmptyPassword = (text: string) => {
  if (text == '') {
      this.setState({ reg3: 'gray' })
      this.setState({ reg4: 'gray' })
      this.setState({ reg1: 'gray' })
      this.setState({ reg2: 'gray' })
  }
}

checkPasswordLength(text: string) {
    if (text.length >= 8) {
      this.setState({ reg1: 'green' })
      return false;
    } else {
      this.setState({ reg1: 'red' })
      return true;
    }
}

checkUpper(text: string, disableBtn: boolean) {
  if (Boolean(text?.match(/[A-Z]/))) {
    this.setState({ reg2: 'green' })
     return disableBtn
  } else {
    this.setState({ reg2: 'red' })
    return true
  }
}

checkLower(text: string, disableBtn: boolean) {
  if (Boolean(text?.match(/[a-z]/))) {
    this.setState({ reg3: 'green' })
    return disableBtn
  } else {
    this.setState({ reg3: 'red' })
    return true
  }
}

checkRegex(text: string, disableBtn: boolean) {
  const pattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|]/;
  if (pattern.test(text)) {
    this.setState({ reg4: 'green' })
    return disableBtn
  } else {
    this.setState({ reg4: 'red' })
    return true
  }
}
imgEnablePasswordFieldProps = {
  source: imgPasswordVisible
};

btnPasswordShowHideProps = {
  onPress: () => {
    this.setState({ enablePasswordField: !this.state.enablePasswordField });
    this.txtInputPasswordProps.secureTextEntry = !this.state
      .enablePasswordField;
    this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
      .secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible;
    let newInputType = this.state.inputType == '' ? 'password' : ''
    this.setState({ inputType: newInputType })
  }
};


txtInputPasswordProps = {
  onChange: (text: string, passwordType:string) => {
    if(passwordType==='password'){
      this.setState({ password: text });
    }
    if(passwordType==='confirmPassword'){
      this.setState({ confirmPassword: text });
    }
    
    let disableBtn = true
    this.checkEmptyPassword(text)
    disableBtn = this.checkPasswordLength(text)
    disableBtn = this.checkUpper(text, disableBtn)
    disableBtn = this.checkLower(text, disableBtn)
    disableBtn = this.checkRegex(text, disableBtn)
    if (disableBtn) {
      this.setState({ reg5: 'green' })
    } else {
      this.setState({ reg5: 'red' })
    }
    let disabled =  !disableBtn
      this.setState({ disable: !disabled })
      this.setState({ passwordValid: !disableBtn })
  },
  secureTextEntry: true
};


handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setConfirmPassword = (text: string) => {
    this.setState({
      confirmPassword: text,
    });
  };
  sendResetLink(){
    const attrs = {
      email: this.state.email
    };

    const header = {
      "Content-Type": "application/json"
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
     
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_forgot_password/forgot_password"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    ); 
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
 

  newCreatePassword(): boolean {
    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }
    if (this.state.confirmPassword === null || this.state.confirmPassword.length === 0) {
        this.showAlert("Error", configJSON.errorPasswordNotValid);
        return false;
      }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "Token":'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTg0LCJleHAiOjE2OTk5NDk0MzZ9._9_jcltbXsizDS89qRByXf8zd0HKu_R96cJkIAlUB_9vQd15VzLMXZW4OMBTYKbMK51lNuRkGeBJuX1hq0Fu6w',
    };

    const attrs = {
      password: this.state.password,
      password_confirmation:this.state.confirmPassword,
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_forgot_password/reset_password"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
  }
handleNameChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value });
  }


  handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: event.target.value });
  }


async componentDidMount(): Promise<void> {
    let role = await getStorageData("userRole")
    this.setState({ userRole: role })
    let rolelevelOne = await getStorageData("userRolelevel1")
    this.setState({ userRolelevelOne: rolelevelOne })
    this.getTemplateList();
    this.templatePostDeatils();
    this.UpdateDataPut(this.state.itemNameEdit, this.state.descriptionEdit)

}

async receive(from: string, message: Message) {
if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let jsonResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

     if (jsonResponse && !jsonResponse.errors) {
        switch (apiRequestCallId) {
            case this.getListApi:
                this.setState({ getUserData: jsonResponse.data });
                break;
            case this.getShowListApi:
                this.setState({ getShowUserData: jsonResponse.data });
                break;
            case this.loginPostDeatilsApi :
                setStorageData("authToken",jsonResponse.meta.token)  
                this.props.navigation.navigate("MultilevelApproval") 
                break; 
             case this.templatesApi : 
                  this.quoteCancel()
                  this.getTemplateList()
                  this.setState({name:'', description:''})

            break;
            case this.UpdateApiPutApi :
                this.getTemplateList()
                this.setState({isEditModalOpen:false})
                this.setState({isStatusModalOpen:false})
                this.closeModal()
                break;
                case this.StatusDataPutApi :
                    this.getTemplateList()
                    break;
                    
        }
    }
}
}

Apidatafunction = async (data: ApiData) => {
    const { contentType, method, endPoint, body, type } = data;
    let token = await getStorageData("authToken")
    
    const header = {
        "Content-Type": contentType,
        token: token,
    };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    request.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    body && type != "formData"
        ? request.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
}

onEditBtnClcik = (item: EventList) => {       
    this.setState({
        isEditModalOpen: true,
        itemNameEdit: item.attributes.name,
        descriptionEdit: item.attributes.description,
        serialIdEdit: item.id,
    });
    }

onShowDetails = () => {
        this.setState({
            isShowModalOpen : true,
        })


}

onStatusDetails = (index:number) => {
    this.setState({
        isStatusModalOpen : true,
       serialIdEdit:index
    })

}

editcloseModal = () => {
    this.setState({ isEditModalOpen: false })
}

showcloseModal = () => {
    this.setState({isShowModalOpen:false})

}

statusCloseModal = () => {
    this.setState({isStatusModalOpen:false})
}

openModal = () => {
    this.setState({ isModalOpen: true });

};


closeModal = () => {
    this.setState({ isModalOpen: false });
    this.setState({ isEditBtn: false })
};


// list 
getTemplateList = async () => {
    this.getListApi = await this.Apidatafunction({
        contentType: "application/json",
        method: "GET",
        endPoint: "bx_block_multilevelapproval/templates",
    });
}

getShowList = async (index:number) => {
    this.getShowListApi = await this.Apidatafunction({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_multilevelapproval/templates/${index}`,
    });
}


onSignIn = () => {
    window.location.replace("./MultilevelApprovalLogin")

}
//Loginuser three  level

handleLogout = async () => {
  await  removeStorageData("authToken")
    window.location.replace("./MultilevelApprovalWelcome")
}

handleClickOpenDialog = () => {
    this.setState({ openDialog: true })
};


loginPostDetails = async () => {
    const payload : LoginPayload = {
        "data" : {
                    "email" : this.state.loginemail,
                    "password" :this.state.loginpassword, 
                 }
        }       
        
    this.loginPostDeatilsApi = await this.Apidatafunction({
        contentType: "application/json",
        method: "POST",
        endPoint: "bx_block_login/logins",
        body: payload 
        
    });
};

onLogin = (event: React.FormEvent) => {
    event.preventDefault();
    this.loginPostDetails();
}

templatePostDeatils = async () => {
    let token =  await getStorageData("authToken")

    const payload:Payload = {template: {
        name: this.state.name,
        description: this.state.description,
        kpis_attributes: {
          0: {
            name: "kpi name",
            description: "kpi des",
            point: 100
          }
        }
        }
    }
                    const header = {
                        token: token,
                      };
                      
        
    this.templatesApi = await this.Apidatafunction({
        header,
        contentType: "application/json",
        method: "POST",
        endPoint: "bx_block_multilevelapproval/templates",
        body: payload
    });
};

handleChange = (event:ChangeEvent) => {
    const { name, value } = event.target
    if(name === "description"){
        this.setState({descriptionEdit: value});
    }
    if(name === "itemNameEdit"){
        this.setState({itemNameEdit: value})
    }
}

UpdateDataPut = async (title:string,discription:string) => {
    let token = await getStorageData("authToken")
    const header = {
        token: token,
      };
      const payload: TemplateData  = {
        template:{
        name:title,
        description:discription,
                  }
    }
      

    this.UpdateApiPutApi = await this.Apidatafunction({
        header,
        contentType: "application/json",
        method: "PUT",
        endPoint: `bx_block_multilevelapproval/templates/${this.state.serialIdEdit}`,
        body:payload
 
    });

}
 statusdropDown = (event:React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedOption: event.target.value })
 }
 
statusChange =()=> {
    const { selectedOption } = this.state;
    const updatedStatus = {...this.state.getShowUserData} ;
    updatedStatus.attributes.status = selectedOption
    this.StatusDataPut()
    this.statusCloseModal()
} 
 
StatusDataPut = async () => {
    let token = await getStorageData ("authToken")
    const header = {
        token: token,
      };
    const payload :TemplateIdData  = {
        
            status:this.state.selectedOption,
            comment:this.state.description,


        
    }

    this.StatusDataPutApi = await this.Apidatafunction({
        header,
        contentType: "application/json",
        method: "PUT",
        endPoint: `bx_block_multilevelapproval/templates/approved_rejected_template?id=${this.state.serialIdEdit}`,
        body:payload
    });
}

togglePasswordVisibility = () => {
    this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
    }));
};

handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.value } as unknown as Pick<
        S,
        keyof S

    >);
};



// Customizable Area End
}

