import { Typography } from "@material-ui/core";
import React from "react";

const ErrorText = ({ text }: { text: string }) => {
  return (
    <Typography
      style={{
        color: 'var(--Error, #FF583A)',
        fontFamily: 'HK Grotesk',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.42px',
        marginTop: '-0.5rem'
      }}>
      {text}
    </Typography >
  )
}

export default ErrorText;
