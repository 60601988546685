import React from "react";

interface CustomStepperProps {
  activeStep?: number,
  maxSteps?: number,
}

const CustomStepper = ({ activeStep = 1, maxSteps = 6 }: CustomStepperProps) => {
  const unReachedStyle = {
    height: '6px',
    width: '6px',
    backgroundColor: 'var(--primary-50, #F3EAFA)',
    borderRadius: '3px',
    display: 'inline-block',
    marginLeft: '8px',
  }

  const reachedStyle = {
    height: '6px',
    width: '6px',
    backgroundColor: 'var(--primary-500, #6A299F)',
    borderRadius: '3px',
    display: 'inline-block',
    marginRight: '8px',
  }

  const activeStyle = {
    height: '6px',
    width: '22px',
    backgroundColor: 'var(--primary-500, #6A299F)',
    borderRadius: '3px',
    display: 'inline-block',
  }

  const dots = []

  for (let i = 1; i <= maxSteps; i++) {
    if (i < activeStep) dots.push(<span data-testid={`Step${i}`} key={i} style={reachedStyle}></span>)
    if (i === activeStep) dots.push(<span data-testid={`Step${i}`} key={i} style={activeStyle}></span>)
    if (i > activeStep) dots.push(<span data-testid={`Step${i}`} key={i} style={unReachedStyle}></span>)
  }

  return (
    <div>
      {dots}
    </div>
  )
}

export default CustomStepper;
