import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import { Box, Paper, Typography } from "@material-ui/core";

const imgContact = require('./contact_footer.png')

interface myProps {
}

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#F9F9F9', 
        boxShadow: 'none', 
        borderRadius: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '116px',
        maxWidth: '90vw',
        flex: 1
    },
    leftBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '36px'
    },
    rightBox: {
        display: 'flex',
        alignItems: 'center'
    },
    footerTxt: {
        fontSize: '19px',
        color: '#999BAC',
        fontFamily: 'HK Grotesk'
    }
}));

export default function LandingPageFooter(props: myProps) {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
        <Box className={classes.container}>
            <Box className={classes.leftBox} >
                <img src={imgContact} alt="logo" />
                <Typography className={classes.footerTxt} >Terms and Conditions</Typography>
                <Typography className={classes.footerTxt} >Privacy Policy</Typography>
            </Box>
            <Box className={classes.rightBox} >
                <Typography className={classes.footerTxt} >Copyright 2023 Caatoo.Inc</Typography>
            </Box>
        </Box>
        </Paper>
    );
}
