import { styled } from "@material-ui/core";

const InsideDiv = styled('div')({
  boxSizing: "border-box",
  padding: "5rem 4rem",
  '@media (max-width: 425px)': {
    padding: "4rem 2rem"
  }
})

export default InsideDiv;
